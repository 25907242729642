import { render, staticRenderFns } from "./CompactCommunity.vue?vue&type=template&id=3b002264&scoped=true&"
import script from "./CompactCommunity.vue?vue&type=script&lang=ts&"
export * from "./CompactCommunity.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b002264",
  null
  
)

export default component.exports