import { Reaction } from "@/Types/Reaction";
import UserSliver from "./UserSliver";

/**
 * The structure of a user creatable response.
 *
 * @export
 * @abstract
 * @class UserCreatableResponse
 */
export default abstract class UserCreatableResponse {
	/**
	 * The ID.
	 *
	 * @type {string}
	 */
	id: string;

	/**
	 * The creation date measured in ticks since the epoch.
	 *
	 * @type {BigInt}
	 */
	creationDate: BigInt;

	/**
	 * Whether or not the user creatable has been deleted.
	 *
	 * @type {boolean}
	 */
	deleted: boolean;

	/**
	 * The creator.
	 *
	 * @type {(UserSliver | null)}
	 */
	creator: UserSliver | null;

	/**
	 * The title.
	 *
	 * @type {string}
	 */
	title: string;

	/**
	 * The repute.
	 *
	 * @type {number}
	 */
	repute: number;

	/**
	 * The users reaction if available, null otherwise.
	 *
	 * @type {(Reaction | null)}
	 */
	userReaction: Reaction | null;

	/**
	 * A list of comments on the user creatable. This contains the comment IDs as a reference to the comments.
	 *
	 * @type {Array<string>}
	 */
	comments: Array<string>;

	/**
	 * Creates an instance of a UserCreatableResponse.
	 *
	 * @param {*} userCreatableResponseJson The user creatable response JSON.
	 */
	constructor(userCreatableResponseJson: any) {
		this.id = userCreatableResponseJson.id;
		this.creationDate = BigInt(userCreatableResponseJson.creationDate);
		this.deleted = userCreatableResponseJson.deleted;
		this.creator = !userCreatableResponseJson.creator
			? null
			: new UserSliver(userCreatableResponseJson.creator);
		this.title = userCreatableResponseJson.title;
		this.repute = userCreatableResponseJson.repute;
		this.userReaction = userCreatableResponseJson.userReaction;
		this.comments = userCreatableResponseJson.comments;
	}
}
