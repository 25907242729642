













































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { PostType } from "@/Types/PostType";
import CreatePostRequest from "@/Types/Rest/Requests/CreatePostRequest";
import CreateTextPostRequest from "@/Types/Rest/Requests/CreateTextPostRequest";
import CreateLinkPostRequest from "@/Types/Rest/Requests/CreateLinkPostRequest";
import CreateImagePostRequest from "@/Types/Rest/Requests/CreateImagePostRequest";
import PostRestService from "@/Api/PostRestService";
import { LoadState } from "@/Types/LoadState";
import TextPostResponse from "../Types/Rest/Responses/TextPostResponse";
import LinkPostResponse from "@/Types/Rest/Responses/LinkPostResponse";
import ImagePostResponse from "@/Types/Rest/Responses/ImagePostResponse";
import UserSliver from '@/Types/Rest/Responses/UserSliver';
import { getModule } from 'vuex-module-decorators';
import AuthenticationModule from '@/Store/Modules/Authentication';
import UserResponse from '@/Types/Rest/Responses/UserResponse';
import UserRestService from '@/Api/UserRestService';
import MarkdownEditor from '@/Components/MarkdownEditor.vue';
import PageContainer from "@/Components/PageContainer.vue";
import marked from 'marked';

const authenticationStore = getModule(AuthenticationModule);

@Component({
	components: {
		MarkdownEditor,
		PageContainer
	}
})
export default class CreateOrEditPost extends Vue {
	postLoadState = LoadState.Loading;

	userLoadState = LoadState.Loading;

	userResponse!: UserResponse;

	postType = PostType.Text;

	title = "";

	titleError = false;

	text = "";

	tags = new Array<string>();

	tagText = "";

	get postId(): string | null {
		return this.$route.query.postId as string | null;
	}

	get userSliver(): UserSliver | null {
		return authenticationStore.userClaims?.userSliver || null;
	}

	get canSubmit(): boolean {
		return !this.isEmptyOrWhiteSpace(this.title) && !this.isEmptyOrWhiteSpace(this.text);
	}

	get previewText(): string {
		const render = new marked.Renderer;

		render.image = function(href: string | null, title: string | null, text: string) {
			return `<img class="marked-image" src="${href}" alt="${text}">`;
		};

		render.paragraph = function(text: string) {
			return `<p class="marked-paragraph">${text}</p>`
		}

		render.code = function(code: string, language: string | undefined, isEscaped: boolean) {
			code = code
				.replace(/&/g, "&amp;")
				.replace(/</g, "&lt;")
				.replace(/>/g, "&gt;")
				.replace(/"/g, "&quot;")
				.replace(/'/g, "&#039;");
			return `<pre class="marked-code-pre"><code class="marked-code">${code}</code></pre>`;
		}

		return marked(this.text, {
			renderer: render
		});
	}

	get tagInput(): HTMLInputElement {
		return document.getElementById("tag-input") as HTMLInputElement;
	}

	mounted() {
		UserRestService.getOne(this.userSliver!.id).then((userResponse: UserResponse) => {
			this.userResponse = userResponse;
			this.userLoadState = LoadState.Loaded
		});

		if (this.postId) {
			// query param has ID
			PostRestService.getOne(this.postId).then(postResponse => {
				this.title = postResponse.title;
				this.tags = postResponse.tags;
				const postType = postResponse.constructor;
				switch (postType) {
					case TextPostResponse:
						this.postType = PostType.Text;
						this.text = (postResponse as TextPostResponse).text;
						break;
					default:
						throw new TypeError(`Post type '${postType}' is not implemented.`);
				}
				this.setLoaded();
			});
		} else {
			this.setLoaded();
		}
	}

	setLoaded() {
		// TODO: This should probably use a watcher or something instead. This method exists only to add the event listener
		this.postLoadState = LoadState.Loaded;

		Vue.nextTick(() => {
			this.tagInput.onkeydown = (keyboardEvent: KeyboardEvent) => {
				if (keyboardEvent.key == " ") {
					// Prevent the default event
					keyboardEvent.preventDefault();

					// Prevent empty tags
					if (this.isEmptyOrWhiteSpace(this.tagText)) return;

					// Push the tag to the tags and clear the tag text
					this.tags.push(this.tagText);
					this.tagText = "";
				} else if (keyboardEvent.keyCode == 8) {
					// Return if the tag text is not empty
					if (this.tagText != "") return;

					// Prevent the default event
					keyboardEvent.preventDefault();

					// Remove the last tag from the tags and add it to the tag text
					this.tagText = this.tags.splice(-1,1)[0];
				}
			};
		});
	}

	removeTag(tagIndex: number) {
		this.tags.splice(tagIndex, 1);
	}

	submit() {
		this.titleError = this.isEmptyOrWhiteSpace(this.title);

		if (this.isEmptyOrWhiteSpace(this.title)) {
			return;
		}

		let post: CreatePostRequest;
		switch (this.postType) {
			case PostType.Text:
				post = new CreateTextPostRequest(this.title, this.tags, this.text);
				break;
			default:
				throw new Error(`Unknown post type '${this.postType}'.`);
		}

		if (this.postId) {
			PostRestService.update(this.postId, post).then(updatedPostResponse => {
				this.$router.push(`/post/${updatedPostResponse.id}`).catch(reason => {
					console.log(reason);
					// TODO: Handle error
				});
			});
		} else {
			PostRestService.create(post)
				.then(post => {
					this.$router.push(`/post/${post.id}`);
				})
				.catch(reason => {
					console.log(reason);
					// TODO: Handle error
				});
		}
	}

	isEmptyOrWhiteSpace(value: string | null | undefined): boolean {
		return !value || /^\s*$/.test(value);
	}
}
