import axios from "axios";
import ApiConfig from "./ApiConfig";

/**
 * A rest service for authenticating with the back end web server.
 *
 * @export
 * @class AuthenticationRestService
 */
export default class AuthenticationRestService {
	/**
	 * Authenticate to sender with a google ID token.
	 *
	 * @param {string} googleToken The google ID token.
	 * @returns {Promise<string>} A promise containing a sender token.
	 */
	static async authenticateWithGoogle(googleToken: string): Promise<string> {
		const response = await axios.post(
			`${ApiConfig.serverUrl}/authentication/google/${googleToken}`
		);
		return response.data as string;
	}
}
