/**
 * Describes the possible load states.
 *
 * @export
 * @enum {string}
 */
export enum LoadState {
	Loading = "Loading",
	Loaded = "Loaded",
	Failed = "Failed"
}
