import { render, staticRenderFns } from "./CreateOrEditPost.vue?vue&type=template&id=365d5264&scoped=true&"
import script from "./CreateOrEditPost.vue?vue&type=script&lang=ts&"
export * from "./CreateOrEditPost.vue?vue&type=script&lang=ts&"
import style0 from "./CreateOrEditPost.vue?vue&type=style&index=0&id=365d5264&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "365d5264",
  null
  
)

export default component.exports