

































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import PostContainer from "@/Components/PostContainer.vue";
import PageContainer from "@/Components/PageContainer.vue";
import ExploreTags from "@/Components/Sidebar/ExploreTags.vue";

@Component({
	components: {
		PostContainer,
		PageContainer,
		ExploreTags
	}
})
export default class TagPage extends Vue {
	get tag(): string {
		return this.$route.params.id;
	}

	tagBackgroundColor(tagName: string): number {
		const seed = Array.from(tagName)
			.map(character => character.charCodeAt(0))
			.reduce((accumulator, currentValue) => accumulator + currentValue);
		return (seed * 7567) % 360;
	}
}
