/**
 * Describes a post type.
 *
 * @export
 * @enum {string}
 */
export enum PostType {
  Text = "Text",
  Link = "Link",
  Image = "Image",
  Comment = "Comment",
}
