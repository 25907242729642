/**
 * Describes the structure of a request to create a comment.
 *
 * @export
 * @class CreateCommentRequest
 */
export default class CreateCommentRequest {
	/**
	 * The value.
	 *
	 * @type {string}
	 */
	value: string;

	/**
	 * Creates an instance of CreateCommentRequest.
	 *
	 * @param {string} value The value.
	 */
	constructor(value: string) {
		this.value = value;
	}
}
