
































































import axios from "axios";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import ImgurRestService from "@/Api/ImgurRestService";
import ClickAwayHandler from "@/Components/ClickAwayHandler.vue";

@Component({
	components: {
		ClickAwayHandler
	}
})
export default class MarkdownEditor extends Vue {
	@Prop({default: ""})
	value!: string;

	displayImageAddOptions = false;

	highlightDropZone = false;

	set text(newText: string) {
		this.$emit('input', newText);
	}

	get text(): string {
		return this.value;
	}

	get textInput(): HTMLTextAreaElement {
		return document.getElementById("input-area") as HTMLTextAreaElement;
	}

	mounted() {
		this.textInput.addEventListener('keydown', keyboardEvent => {
			if (keyboardEvent.key == 'Tab') {
				// TODO: add more sophisticated tabbing for indenting multiple lines or undoing such an operating

				// Prevent the default event
				keyboardEvent.preventDefault();
		
				// Get the start and end of the selection
				const start = this.textInput.selectionStart;
				const end = this.textInput.selectionEnd;

				// set textarea value to: text before caret + tab + text after caret
				this.textInput.value = this.textInput.value.substring(0, start) +
				"\t" + this.textInput.value.substring(end);

				// put caret at right position again
				this.textInput.selectionStart =
				this.textInput.selectionEnd = start + 1;
			}
		});
	}
	
	browseClicked() {
		(document.getElementById("image-browse") as HTMLInputElement).click();
	}

	onDragEnter(event: DragEvent) {
		this.highlightDropZone = true;
	}

	onDragLeave(event: DragEvent) {
		this.highlightDropZone = false;
	}

	onDragOver(dragEvent: DragEvent) {
		// This is required to ensure the "drop zone" is a valid drop target
		dragEvent.stopPropagation();
		dragEvent.preventDefault();
		this.highlightDropZone = true;
	}

	onDrop(event: DragEvent) {
		// This is required to ensure the browser doesn't open the image
		event.stopPropagation();
		event.preventDefault();

		this.highlightDropZone = false;
		this.displayImageAddOptions = false;

		const file = event.dataTransfer!.files[0] as File;

		ImgurRestService.postImage(file).then(response => {
			this.addWrappedText(`![`, `optional image description`, `](${response.data.data.link})`)
		});
	}

	onFileChange(event: any) {
		this.displayImageAddOptions = false;

		const files = event.target.files || event.dataTransfer.files;
		if (!files.length)
			return;
		const file = files[0] as File;

		ImgurRestService.postImage(file).then(response => {
			this.addWrappedText(`![`, `optional image description`, `](${response.data.data.link})`)
		});
	}

	insertItemOnNextLine(item: string) {
		// Get the end index
		const end = this.textInput.selectionEnd;

		let lineEnd = this.text.indexOf('\n', end);

		if (lineEnd < 0) {
			this.text += '\n';
			lineEnd = this.text.length - 1;
		}

		this.text = this.text.slice(0, lineEnd + 1) + item + this.text.slice(lineEnd)
	}

	togglePrependLine(prepend: string, placeholder: string) {
		// Get the start and end of the selection
		const start = this.textInput.selectionStart;
		const end = this.textInput.selectionEnd;

		// Split the text into lines
		// const lines = this.text.split(/(?<=\n)/);
		const lines = this.text.split(/(?:\n)/);

		// get The index of the start and end line
		let startLine = this.stringIndexToLineNumber(Math.min(start, this.text.length - 1), lines);
		let endLine = this.stringIndexToLineNumber(Math.max(end - 1, start), lines);
		if (endLine < 0) endLine = lines.length - 1;

		// Check if multiple lines are selected, which should not be supported
		if (startLine != endLine) return;

		let actionPerformed = false;
		if (lines[startLine].startsWith(prepend)) {
			// Undo the action
			lines[startLine] = lines[startLine].slice(prepend.length);
		} else {
			// Perform the action
			actionPerformed = true;
			lines[startLine] = prepend + (this.isEmptyOrWhiteSpace(lines[startLine]) ? placeholder + "\n" : lines[startLine]);
		}

		this.text = lines.join("");

		this.textInput.focus();

		Vue.nextTick(() => {
			this.textInput.selectionStart = this.lineNumberToStartIndex(startLine, lines) + (actionPerformed ? prepend.length : 0);
			this.textInput.selectionEnd = this.lineNumberToStartIndex(startLine, lines) + lines[startLine].length - 1;
		});
	}

	multiLineWrapToggle(ends: string) {
		// Get the start and end of the selection
		const start = this.textInput.selectionStart;
		const end = this.textInput.selectionEnd;

		// Split the text into lines
		// const lines = this.text.split(/(?<=\n)/);
		const lines = this.text.split(/(?:\n)/);

		// get The index of the start and end line
		let startLine = this.stringIndexToLineNumber(Math.min(start, this.text.length - 1), lines);
		let endLine = this.stringIndexToLineNumber(Math.max(end - 1, start), lines);
		if (endLine < 0) endLine = lines.length - 1;

		// Get the previous and next lines
		const previousLine = lines[startLine - 1];
		const nextLine = lines[endLine + 1];

		const endWithReturn = ends + "\n";

		if (previousLine?.startsWith(ends) && nextLine?.startsWith(ends)) {
			// Undo the action
			lines.splice(endLine + 1, 1);
			endLine--;
			startLine--;
			lines.splice(startLine, 1);
		} else {
			// Perform the action
			if (!lines[endLine].endsWith("\n")) lines[endLine] += "\n";
			endLine++;
			lines.splice(endLine, 0, endWithReturn);
			lines.splice(startLine, 0, endWithReturn);
			startLine++;
		}

		this.text = lines.join("");

		this.textInput.focus();

		Vue.nextTick(() => {
			this.textInput.selectionStart = this.lineNumberToStartIndex(startLine, lines);
			this.textInput.selectionEnd = this.lineNumberToStartIndex(endLine, lines) + lines[endLine].length;
		});
	}

	multiLinePrependToggle(prependCallback: (lineNumber: number) => string) {
		// Get the start and end of the selection
		const start = this.textInput.selectionStart;
		const end = this.textInput.selectionEnd;

		// Get the start and end of the selection adjusted for the whole selected line
		const adjustedStart = this.text.lastIndexOf('\n', start - 1) + 1;
		let adjustedEnd = this.text.indexOf('\n', Math.max(end - 1, start));
		if (adjustedEnd < 0) adjustedEnd = this.text.length - 1;

		// Capture the selected lines from the adjusted indices
		const selectedLines = this.text.slice(adjustedStart, adjustedEnd + 1);

		// Split the lines into an array keeping the newline delimiter
		// const lines = this.text.split(/(?<=\n)/);
		const lines = this.text.split(/(?:\n)/);

		let newText = "";
		if (lines.every((value: string, index: number) => value.startsWith(prependCallback(index)))) {
			// undo the operation
			lines.forEach((value: string, index: number) => {
				newText += value.slice(prependCallback(index).length)
			});
		} else {
			// perform the operation
			lines.forEach((value: string, index: number) => {
				newText += prependCallback(index) + value;
			});
		}
		this.text = this.text.slice(0, adjustedStart) + newText + this.text.slice(adjustedEnd + 1);

		this.textInput.focus();

		Vue.nextTick(() => {
			this.textInput.selectionStart = adjustedStart;
			this.textInput.selectionEnd = adjustedStart + newText.length;
		});
	}

	addWrappedText(startWrap: string, placeholder: string, endWrap: string) {
		const start = this.textInput.selectionStart;
		const end = this.textInput.selectionEnd;
		const highlightText = this.textInput.value.substring(start, end) || placeholder;

		this.text = this.text.slice(0, start) + startWrap + highlightText + endWrap + this.text.slice(end);

		Vue.nextTick(() => {
			this.textInput.focus();
			this.textInput.selectionStart = start + startWrap.length;
			this.textInput.selectionEnd = start + startWrap.length + highlightText.length;
		});
	}

	// Toggle the wrap of selected text. Ex: ends: "**" -> **selected** or undoes the operation
	// FIXME: This isn't quite fully functional when used with bold and italics at the same time
	toggleWrapOnSelected(ends: string, placeholder: string) {
		const start = this.textInput.selectionStart;
		const end = this.textInput.selectionEnd;
		const offset = ends.length;

		// Check if text is selected
		if (start != end) {
			const selectedText = this.textInput.value.substring(start, end);

			// Check if multiple lines are selected, which should not be supported
			if (selectedText.includes("\n")) return;

			// Check if we need to un-bold
			if (this.text.slice(start - offset, start) == ends && this.text.slice(end, end + offset) == ends) {
				this.text = this.text.slice(0, start - offset) + this.text.slice(start, end) + this.text.slice(end + offset);

				this.textInput.focus();
				
				Vue.nextTick(() => {
					this.textInput.selectionStart = start - offset;
					this.textInput.selectionEnd = end - offset;
				});
				return;
			} else {
				this.text = this.text.slice(0, start) + ends + this.text.slice(start, end) + ends + this.text.slice(end);

				this.textInput.focus();

				Vue.nextTick(() => {
					this.textInput.selectionStart = start + offset;
					this.textInput.selectionEnd = end + offset;
				});
			}
		} else {
			this.text = this.text.slice(0, start) + `${ends}${placeholder}${ends}` + this.text.slice(start);
			this.textInput.focus();

			Vue.nextTick(() => {
				this.textInput.selectionStart = start + offset;
				this.textInput.selectionEnd = start + offset + placeholder.length;
			});
		}
		return;
	}

	private isEmptyOrWhiteSpace(value: string | null | undefined): boolean {
		return !value || /^\s*$/.test(value);
	}

	private lineNumberToStartIndex(lineNumber: number, lines: string[]): number {
		let charactersCounted = 0;
		for(let indexLine = 0; indexLine < Math.min(lines.length, lineNumber); indexLine++) {
			charactersCounted += lines[indexLine].length;
		}

		return charactersCounted;
	}

	private stringIndexToLineNumber(index: number, lines: string[]): number {
		let charactersCounted = 0;
		for(let lineNumber = 0; lineNumber < lines.length; lineNumber++) {
			const line = lines[lineNumber];
			if (index >= charactersCounted && index < charactersCounted + line.length) return lineNumber;
			charactersCounted += line.length;
		}

		return -1;
	}
}
