




































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import CommentResponse from "../../../Types/Rest/Responses/CommentResponse";
import { Reaction } from "@/Types/Reaction";
import * as _ from "lodash";
import marked from "marked";
import { getModule } from "vuex-module-decorators";
import AuthenticationModule from "@/Store/Modules/Authentication";
import CommentRestService from "@/Api/CommentRestService";
import Login from "@/Components/Login.vue";
import CreateOrEditCommentOverlay from "./CreateOrEditCommentOverlay.vue";
import { LoadState } from "@/Types/LoadState";
import UserSliver from "@/Types/Rest/Responses/UserSliver";
import ClickAwayHandler from "@/Components/ClickAwayHandler.vue";
import CreateCommentRequest from "@/Types/Rest/Requests/CreateCommentRequest";

const authenticationStore = getModule(AuthenticationModule);

const reactionsToReputes = new Map<Reaction, number>();
reactionsToReputes.set(Reaction.Like, 1);
reactionsToReputes.set(Reaction.Dislike, -1);

@Component({
	components: {
		Login,
		CreateOrEditCommentOverlay,
		ClickAwayHandler
	}
})
export default class Comment extends Vue {
	@Prop({ required: true })
	commentResponse!: CommentResponse;

	@Prop()
	depth!: number | null;

	internalCommentResponse: CommentResponse = _.cloneDeep(this.commentResponse);

	displayLogin = false;

	displayReply = false;

	nestedCommentResponses!: Array<CommentResponse>;

	commentsLoadState = LoadState.Loading;

	displayDropdown = false;

	initialComment: string | null = null;

	get userSliver(): UserSliver | null {
		return authenticationStore.userClaims?.userSliver || null;
	}

	get isAuthenticated(): boolean {
		return authenticationStore.isAuthenticated;
	}

	get prettyRepute(): string {
		return (
			this.internalCommentResponse.repute +
			(!!this.internalCommentResponse.userReaction
				? reactionsToReputes.get(this.internalCommentResponse.userReaction)
				: 0)!
		).toString();
	}

	get userReaction(): Reaction | null {
		return this.internalCommentResponse!.userReaction;
	}

	get formattedText(): string {
		return marked(this.internalCommentResponse.value, {
			langPrefix: ""
		});
	}

	mounted() {
		CommentRestService.getMany(this.internalCommentResponse.comments).then(
			commentResponses => {
				this.nestedCommentResponses = commentResponses;
				this.commentsLoadState = LoadState.Loaded;
			}
		);
	}

	created() {
		// Remove the user reaction from the reputation to make downstream calculations easier
		this.adjustRepute();
	}

	adjustRepute() {
		// Remove the user reaction from the reputation to make downstream calculations easier
		this.internalCommentResponse.repute -= (!!this.internalCommentResponse
			.userReaction
			? reactionsToReputes.get(this.internalCommentResponse.userReaction)
			: 0)!;
	}

	react(reaction: Reaction) {
		if (!authenticationStore.isAuthenticated) {
			this.displayLogin = true;
			// TODO: figure out a way to still perform the reaction
			return;
		}

		const oldReaction = this.internalCommentResponse.userReaction;

		if (oldReaction === reaction) {
			this.internalCommentResponse.userReaction = null;
			CommentRestService.removeReaction(this.internalCommentResponse.id).catch(
				reason => {
					// TODO: Handle error
				}
			);
		} else {
			this.internalCommentResponse.userReaction = reaction;
			CommentRestService.react(this.internalCommentResponse.id, reaction).catch(
				reason => {
					// TODO: Handle error
				}
			);
		}
	}

	editComment() {
		console.log("edit");
		this.displayDropdown = false;
		this.initialComment = this.internalCommentResponse.value;
		this.displayReply = true;
	}

	replySubmitted(createCommentRequest: CreateCommentRequest) {
		this.displayReply = false;
		if (this.initialComment != null) {
			// Edit the original comment
			CommentRestService.update(
				this.internalCommentResponse.id,
				createCommentRequest
			).then(commentResponse => {
				this.internalCommentResponse = commentResponse;
				this.adjustRepute();
			});
		} else {
			// Submit a reply
			CommentRestService.comment(
				this.internalCommentResponse.id,
				createCommentRequest
			).then(commentResponse => {
				this.internalCommentResponse.comments.unshift(commentResponse.id);
				this.nestedCommentResponses.unshift(commentResponse);
			});
		}
	}

	confirmDelete() {
		// TODO: Confirm the delete action
		CommentRestService.delete(this.internalCommentResponse.id)
			.then(() => {
				this.internalCommentResponse.deleted = true;
			})
			.catch(reason => {
				// TODO: Handle error
				console.log(reason);
			});
	}
}
