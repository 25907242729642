import axios from "axios";
import { getModule } from "vuex-module-decorators";
import AuthenticationModule from "@/Store/Modules/Authentication";
import ApiConfig from "./ApiConfig";
import UserResponse from "@/Types/Rest/Responses/UserResponse";
import UpdateUserRequest from "@/Types/Rest/Requests/UpdateUserRequest";

const authenticationStore = getModule(AuthenticationModule);

/**
 * A rest service for interacting with the user API.
 *
 * @export
 * @class UserRestService
 */
export default class UserRestService {
	/**
	 * Get a single user by their ID.
	 *
	 * @static
	 * @param {string} id The ID.
	 * @returns {Promise<UserResponse>} A promise containing the user response.
	 */
	public static async getOne(id: string): Promise<UserResponse> {
		return axios
			.get(`${ApiConfig.serverUrl}/user/${id}`, {
				headers: {
					Authorization: `Bearer ${authenticationStore.senderToken}`
				}
			})
			.then(response => {
				return new UserResponse(response.data);
			});
	}

	/**
	 * Update the user.
	 *
	 * @static
	 * @param {UpdateUserRequest} updateUserRequest The user update request.
	 * @returns {Promise<void>} An empty promise.
	 */
	public static async update(
		updateUserRequest: UpdateUserRequest
	): Promise<void> {
		return axios.put(
			`${ApiConfig.serverUrl}/user`,
			JSON.stringify(updateUserRequest),
			{
				headers: {
					Authorization: `Bearer ${authenticationStore.senderToken}`,
					"Content-Type": "application/json"
				}
			}
		);
	}
}
