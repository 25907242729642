import { getModule } from "vuex-module-decorators";
import AuthenticationModule from "@/Store/Modules/Authentication";
import { LoadState } from "@/Types/LoadState";
import AuthenticationRestService from "@/Api/AuthenticationRestService";

/**
 * The Google client ID for the application.
 */
const clientId = process.env.VUE_APP_GOOGLE_CLIENT_ID;

const authenticationStore = getModule(AuthenticationModule);

export default class GoogleUtil {
	/**
	 * Initialize the Google authentication APIs.
	 *
	 * @static
	 */
	public static init(): void {
		// Ensure the gapi library is included
		if (!window.gapi) {
			throw new Error(
				'"https://apis.google.com/js/api:client.js" needs to be included as a <script>.'
			);
		}

		// Ensure a client ID is set
		if (!clientId) {
			throw new Error("Client Id must be specified.");
		}

		this.loadGoogleAuthentication();
	}

	/**
	 * Load the google authentication APIs.
	 *
	 * @static
	 */
	public static loadGoogleAuthentication() {
		// Load the auth2 API
		window.gapi.load("auth2", () => {
			// Begin initialization of the auth2 API
			const googleAuth = window.gapi.auth2.init({ client_id: clientId });

			// Handle the auth2 API initialization finishing or failing
			googleAuth.then(
				() => {
					// Initialization has finished successfully
					authenticationStore.setGoogleLoadState(LoadState.Loaded);
				},
				(reason: { error: string; details: string }) => {
					// Initialization has failed
					authenticationStore.setGoogleLoadState(LoadState.Failed);
					console.log(
						`Failed initializing "auth2". ${reason.error} ${reason.details}`
					);
				}
			);
		});
	}

	/**
	 * Get the google ID token for the signed in Google user.
	 *
	 * @static
	 * @returns {string} The Google ID token.
	 */
	public static getUserToken(): string {
		return window.gapi.auth2
			.getAuthInstance()
			.currentUser.get()
			.getAuthResponse().id_token;
	}

	/**
	 * Login to the backend web server.
	 *
	 * @static
	 * @returns {Promise<void>} An empty promise.
	 */
	public static async login(): Promise<void> {
		await window.gapi.auth2.getAuthInstance().signIn();
		const senderToken = await AuthenticationRestService.authenticateWithGoogle(
			GoogleUtil.getUserToken()
		);
		authenticationStore.setSenderToken(senderToken);
	}

	/**
	 * Refresh the current sender token.
	 *
	 * @static
	 */
	public static async refreshToken() {
		const senderToken = await AuthenticationRestService.authenticateWithGoogle(
			GoogleUtil.getUserToken()
		);
		authenticationStore.setSenderToken(senderToken);
	}

	/**
	 * Sign the user out of google.
	 *
	 * @static
	 */
	public static signOut(): void {
		window.gapi.auth2
			.getAuthInstance()
			.signOut()
			.then();
		window.gapi.auth2
			.getAuthInstance()
			.disconnect()
			.then();
	}
}
