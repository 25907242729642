/**
 * Describes the structure of a request to create a post.
 *
 * @export
 * @abstract
 * @class CreatePostRequest
 */
export default abstract class CreatePostRequest {
	/**
	 * The title.
	 *
	 * @type {string}
	 */
	title: string;

	/**
	 * A list of tags.
	 *
	 * @type {Array<string>}
	 */
	tags: Array<string>;

	/**
	 * Creates an instance of CreatePostRequest.
	 *
	 * @param {string} title The title.
	 * @param {Array<string>} tags A list of tags.
	 */
	constructor(title: string, tags: Array<string>) {
		this.title = title;
		this.tags = tags;
	}
}
