
















































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import UserResponse from "@/Types/Rest/Responses/UserResponse";
import UserRestService from "@/Api/UserRestService";
import UpdateUserRequest from "@/Types/Rest/Requests/UpdateUserRequest";
import ClickAwayHandler from "@/Components/ClickAwayHandler.vue"
import PopupContainer from "@/Components/Popup/PopupContainer.vue"

@Component({
	components: {
		ClickAwayHandler,
		PopupContainer
	}
})
export default class EditProfilePopup extends Vue {
	// The initial user response
	@Prop({required: true})
	userResponse!: UserResponse

	// The request to update the user, initiated from the existing values
	updateUserRequest = new UpdateUserRequest(
		this.userResponse.imageUrl,
		this.userResponse.bannerImageUrl,
		this.userResponse.displayName,
		this.userResponse.aboutInfo
	);

	// User inputs
	bannerImageUrl = this.userResponse.bannerImageUrl;
	profilePictureUrl = this.userResponse.imageUrl;
	displayName = this.userResponse.displayName;
	aboutInfo = this.userResponse.aboutInfo;

	// Possible errors or loadings
	profilePictureUrlError = false;
	profilePictureUrlLoading = false;
	bannerImageUrlError = false;
	bannerImageUrlLoading = false;
	displayNameError = false;

	get profilePictureUrlOrNull(): string | undefined {
		return this.profilePictureUrl;
	}

	get submitEnabled(): boolean {
		// Verify the display name
		if (this.isEmptyOrWhiteSpace(this.displayName)) {
			return false;
		}

		// Verify the profile image url is not loading or invalid
		if (this.profilePictureUrlLoading || this.profilePictureUrlError) {
			return false;
		}

		// Verify the banner image url is not loading or invalid
		if (this.bannerImageUrlLoading || this.bannerImageUrlError) {
			return false;
		}

		return true;
	}

	submit() {
		UserRestService.update(this.updateUserRequest)
			.then(() => {
				// The token contains outdated information at this point, as the user has been modified
				// GoogleUtil.refreshToken();
				// this.$router.go(-1);
				this.$emit("updated");
			})
			.catch(reason => {
				// TODO: Handle
			});
	}

	@Watch("profilePictureUrl")
	profilePictureUrlUpdated() {
		this.profilePictureUrlLoading = true;
	}

	profilePictureUrlUpdateFinished(focusEvent: FocusEvent) {
		// Get the updated url
		const updatedUrl = (<HTMLInputElement>focusEvent.target).value;

		// Check whether or not the new url is valid
		var imageRequest = new XMLHttpRequest()
		imageRequest.open("GET", updatedUrl, true)
		imageRequest.send();
		imageRequest.onload = () => {
			if (imageRequest.status == 200 && this.isValidHttpUrl(updatedUrl)) {
				// The image url is valid
				this.updateUserRequest.imageUrl = updatedUrl;
				this.profilePictureUrlError = false;
			} else {
				// The image url is not valid
				this.profilePictureUrlError = true;
			}
			this.profilePictureUrlLoading = false;
		};
		imageRequest.onerror = imageRequest.onload;
	}

	@Watch("bannerImageUrl")
	bannerImageUrlUpdated() {
		this.bannerImageUrlLoading = true;
	}

	bannerImageUrlUpdateFinished(focusEvent: FocusEvent) {
		// Get the updated url
		const updatedUrl = (<HTMLInputElement>focusEvent.target).value;

		// Check whether or not the new url is valid
		var imageRequest = new XMLHttpRequest()
		imageRequest.open("GET", updatedUrl, true)
		imageRequest.send();
		imageRequest.onload = () => {
			if (imageRequest.status === 200 && this.isValidHttpUrl(updatedUrl)) {
				// The image url is valid
				this.updateUserRequest.bannerImageUrl = updatedUrl;
				this.bannerImageUrlError = false;
			} else {
				// The image url is not valid
				this.bannerImageUrlError = true;
			}
			this.bannerImageUrlLoading = false;
		};
		imageRequest.onerror = imageRequest.onload;
	}

	displayNameUpdateFinished(focusEvent: FocusEvent) {
		// Get the updated display name
		const updatedDisplayName = (<HTMLInputElement>focusEvent.target).value;

		if (this.isEmptyOrWhiteSpace(updatedDisplayName)) {
			this.displayNameError = true;
		} else {
			this.displayNameError = false;
		}
	}

	isEmptyOrWhiteSpace(value: string | null | undefined): boolean {
		return !value || /^\s*$/.test(value);
	}

	isValidHttpUrl(urlString: string) {
		var url;
		
		try {
			url = new URL(urlString);
		} catch (_) {
			return false;
		}

		return url.protocol === "http:" || url.protocol === "https:";
	}
}
