


































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import GoogleSignInButton from "@/Components/GoogleSignInButton.vue";
import { getModule } from "vuex-module-decorators";
import AuthenticationModule from "@/Store/Modules/Authentication";
import GoogleUtil from "@/Util/GoogleUtil";
import UserSliver from "../Types/Rest/Responses/UserSliver";

const authenticationStore = getModule(AuthenticationModule);

/**
 * Header component for mobile devices.
 */
@Component({
	components: {
		GoogleSignInButton
	}
})
export default class MobileHeader extends Vue {
	displayOverlayMenu = false;

	get isAuthenticated(): boolean {
		return authenticationStore.isAuthenticated;
	}

	get userId(): string | null {
		return authenticationStore.userClaims?.userSliver.id ?? null;
	}

	toProfile() {
		this.displayOverlayMenu = false;
		this.$router.push(`/user/${this.userId}`);
	}

	showOverlayMenu(shouldShow: boolean) {
		this.displayOverlayMenu = shouldShow;
	}

	signOut() {
		authenticationStore.setSenderToken(null);
		GoogleUtil.signOut();
	}
}
