


























import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import GoogleSignInButton from "@/Components/GoogleSignInButton.vue";
import { getModule } from "vuex-module-decorators";
import AuthenticationModule from "@/Store/Modules/Authentication";

const authenticationStore = getModule(AuthenticationModule);

/**
 * Header component for mobile devices.
 */
@Component({
	components: {
		GoogleSignInButton
	}
})
export default class Login extends Vue {
	mounted() {
		window.addEventListener("click", this.clickAwayHandler);
		// TODO: Temporarily disable scrolling
	}

	destroyed() {
		window.removeEventListener("click", this.clickAwayHandler);
	}

	signInEvent(successful: boolean) {
		this.$emit("sign-in", successful);
	}

	clickAwayHandler(event: MouseEvent) {
		if (
			!document
				.getElementById("overlay-container")
				?.contains(event.target as HTMLElement)
		) {
			this.$emit("cancel");
		}
	}
}
