












import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import AuthenticationModule from "@/Store/Modules/Authentication";
import { LoadState } from "@/Types/LoadState";
import AuthenticationRestService from "@/Api/AuthenticationRestService";
import GoogleUtil from "@/Util/GoogleUtil";

const authenticationStore = getModule(AuthenticationModule);

/**
 * A button for signing into Sender using Google authentication.
 */
@Component({
	computed: {
		googleLoadState: (): LoadState => {
			return authenticationStore.googleLoadState;
		}
	}
})
export default class GoogleSignInButton extends Vue {
	@Prop({ required: true })
	buttonText!: string;

	private signIn(): void {
		GoogleUtil.login()
			.then(() => this.$emit("sign-in", true))
			.catch(reason => this.$emit("sign-in", false));
	}
}
