/**
 * Describes a reaction.
 *
 * @export
 * @enum {string}
 */
export enum Reaction {
	Like = "Like",
	Dislike = "Dislike"
}
