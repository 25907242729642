/**
 * The structure of an update user request.
 *
 * @export
 * @class UpdateUserRequest
 */
export default class UpdateUserRequest {
	/**
	 * The image URL. Null indicates no image for the user.
	 *
	 * @type {(string | null)}
	 */
	imageUrl: string | null;

	/**
	 * The banner image URL. Null indicates no banner image for the user.
	 *
	 * @type {(string | null)}
	 */
	bannerImageUrl: string | null;

	/**
	 * The display name. Null indicates no change.
	 *
	 * @type {(string | null)}
	 */
	displayName: string | null;

	/**
	 * The about info. Null indicates no change.
	 *
	 * @type {(string | null)}
	 */
	aboutInfo: string | null;

	constructor(
		imageUrl: string | null,
		bannerImageUrl: string | null,
		displayName: string | null,
		aboutInfo: string | null
	) {
		this.imageUrl = imageUrl;
		this.bannerImageUrl = bannerImageUrl;
		this.displayName = displayName;
		this.aboutInfo = aboutInfo;
	}
}
