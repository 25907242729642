import Vue from "vue";
import App from "./App.vue";
import router from "./Router";
import store from "./Store";

Vue.config.productionTip = false;

// Importing the theme files
import "@/Theme/DarkTheme.scss";
import "@/Theme/LightTheme.scss";

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount("#app");
