/**
 * The possible sort modes.
 *
 * @export
 * @enum {string}
 */
export enum SortMode {
	New = "New",
	Popular = "Popular"
}
