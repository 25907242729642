/**
 * The structure of a user response.
 *
 * @export
 * @class UserResponse
 */
export default class UserResponse {
	/**
	 * The ID.
	 *
	 * @type {string}
	 */
	id: string;

	/**
	 * The display name.
	 *
	 * @type {string}
	 */
	displayName: string;

	/**
	 * The about info.
	 *
	 * @type {string}
	 */
	aboutInfo: string;

	/**
	 * The image URL. Null indicates no image for the user.
	 *
	 * @type {string}
	 */
	imageUrl: string;

	/**
	 * The banner image URL. Null indicates no banner image for the user.
	 *
	 * @type {string}
	 */
	bannerImageUrl: string;

	/**
	 * The repute of the user.
	 *
	 * @type {number}
	 */
	repute: number;

	/**
	 * The number of posts by the user.
	 *
	 * @type {number}
	 */
	postsCount: number;

	/**
	 * The number of comments by the user.
	 *
	 * @type {number}
	 */
	commentsCount: number;

	/**
	 * Creates an instance of UserResponse.
	 *
	 * @param {*} userResponseJson The suer response json.
	 */
	constructor(userResponseJson: any) {
		this.id = userResponseJson.id;
		this.displayName = userResponseJson.displayName;
		this.aboutInfo = userResponseJson.aboutInfo;
		this.imageUrl = userResponseJson.imageUrl;
		this.bannerImageUrl = userResponseJson.bannerImageUrl;
		this.repute = userResponseJson.repute;
		this.postsCount = userResponseJson.postsCount;
		this.commentsCount = userResponseJson.commentsCount;
	}
}
