


































































import { Component, Vue } from "vue-property-decorator";
import { LoadState } from "@/Types/LoadState";
import UserRestService from "@/Api/UserRestService";
import UserResponse from "../../Types/Rest/Responses/UserResponse";
import PostRestService from "../../Api/PostRestService";
import PostResponse from "@/Types/Rest/Responses/PostResponse";
import EditProfilePopup from "./Components/EditProfilePopup.vue"
import UserSliver from "@/Types/Rest/Responses/UserSliver";
import { getModule } from "vuex-module-decorators";
import AuthenticationModule from "@/Store/Modules/Authentication";
import PostContainer from "@/Components/PostContainer.vue";
import PageContainer from "@/Components/PageContainer.vue";
import ExploreTags from "@/Components/Sidebar/ExploreTags.vue";
import AdvertisementPromotion from "@/Components/Sidebar/AdvertisementPromotion.vue";
import TopPosts from "@/Components/Sidebar/TopPosts.vue";
import SupportUs from "@/Components/Sidebar/SupportUs.vue";
import SiteMeta from "@/Components/Sidebar/SiteMeta.vue";

const authenticationStore = getModule(AuthenticationModule);

@Component({
	components: {
		EditProfilePopup,
		PostContainer,
		PageContainer,
		ExploreTags,
		AdvertisementPromotion,
		TopPosts,
		SupportUs,
		SiteMeta,
	}
})
export default class User extends Vue {
	userLoadState = LoadState.Loading;

	internalUserResponse!: UserResponse;

	postsLoadState = LoadState.Loading;

	internalPostsResponse!: Array<PostResponse>;

	overflowExpanded = false;

	maxHeight = 300;

	showPopup = false;

	get userSliver(): UserSliver | null {
		return authenticationStore.userClaims?.userSliver || null;
	}

	expand() {
		console.log("expanded");
	}

	mounted() {
		UserRestService.getOne(this.$route.params.id)
			.then(userResponse => {
				this.internalUserResponse = userResponse;
				this.userLoadState = LoadState.Loaded;
				return PostRestService.getMany(this.internalUserResponse.id);
			})
			.then(postResponses => {
				this.internalPostsResponse = postResponses;
				this.postsLoadState = LoadState.Loaded;
			})
			.catch(reason => {
				// TODO:
			});
	}

	userUpdated() {
		this.userLoadState = LoadState.Loading;
		this.showPopup = false;
		UserRestService.getOne(this.$route.params.id)
			.then(userResponse => {
				this.internalUserResponse = userResponse;
				this.userLoadState = LoadState.Loaded;
			})
	}
}
