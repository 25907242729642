

















import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import PageContainer from "@/Components/PageContainer.vue";

@Component({
	components: {
		PageContainer
	}
})
export default class AboutPage extends Vue {}
