




























































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import PostResponse from "@/Types/Rest/Responses/PostResponse";
import PostRestService from "@/Api/PostRestService";
import { getModule } from "vuex-module-decorators";
import AuthenticationModule from "@/Store/Modules/Authentication";
import { LoadState } from "@/Types/LoadState";
import { SortMode } from "@/Types/SortMode";
import AltCompactPost from "@/Components/AltCompactPost.vue";
import PopupContainer from "@/Components/Popup/PopupContainer.vue"

const authenticationStore = getModule(AuthenticationModule);

@Component({
	components: {
		AltCompactPost,
		PopupContainer
	}
})
export default class PostContainer extends Vue {
	@Prop({ default: undefined })
	userIdFilter!: string | undefined;

	@Prop({ default: undefined })
	tagFilter!: string | undefined;

	postsLoadState = LoadState.Loading;

	nextPageLoadState = LoadState.Loaded;

	lastPageLoaded = false;

	sortMode = SortMode.New;

	selectPosts = false;

	selectedPosts: Array<String> = [];

	showConfirmDelete = false;

	// This property must be initialized in order for Vue to detect changes to it
	postResponses: Array<PostResponse> = [];

	get isAuthenticated(): boolean {
		return authenticationStore.isAuthenticated;
	}

	get isAdmin(): boolean {
		return true;
	}

	get lastPost(): HTMLElement | null {
		return document.getElementById(
			this.postResponses[this.postResponses.length - 1].id
		);
	}

	@Watch("isAuthenticated")
	authenticationChanged() {
		this.getOrRefreshPosts();
	}

	mounted() {
		this.getOrRefreshPosts();

		window.onscroll = () => {
			// Make sure the first page has loaded
			if (this.postsLoadState != LoadState.Loaded) return;

			// Make sure the next page is not loading
			if (this.nextPageLoadState != LoadState.Loaded) return;

			// Make sure we haven't reached the last page
			if (this.lastPageLoaded) return;

			// Check if last post is scrolled into view
			if (!this.isScrolledIntoView(this.lastPost)) return;

			// If it is scrolled into view load the next page (and set the loading to true)
			this.nextPageLoadState = LoadState.Loading;
			PostRestService.getMany(
				this.userIdFilter,
				this.tagFilter,
				this.sortMode,
				10,
				this.postResponses[this.postResponses.length - 1].id
			).then(postResponses => {
				// Once loaded, see if a full page was returned. If not, end of pages reached, if so set loading to false (so the next page can be loaded)
				if (postResponses.length < 10) this.lastPageLoaded = true;
				this.postResponses.push(...postResponses);
				this.nextPageLoadState = LoadState.Loaded;
			});
		};
	}

	toggleSelectPosts() {
		this.selectPosts = !this.selectPosts;
		if (!this.selectPosts) this.selectedPosts = [];
	}

	isSelected(postId: string) {
		return this.selectedPosts.includes(postId);
	}

	toggleSelected(postId: string) {
		if (this.isSelected(postId)) this.selectedPosts.splice(this.selectedPosts.indexOf(postId) , 1);
		else  this.selectedPosts.push(postId);
	}

	deletePosts() {
		console.log(`deleting ${JSON.stringify(this.selectedPosts)}`)
		
	}

	getOrRefreshPosts() {
		this.postsLoadState = LoadState.Loading;

		PostRestService.getMany(
			this.userIdFilter,
			this.tagFilter,
			this.sortMode,
			10
		)
			.then(postResponses => {
				this.postResponses = postResponses;
				this.postsLoadState = LoadState.Loaded;
			})
			.catch(reason => {
				console.log(reason);
				// TODO: Handle error
			});
	}

	sortBy(sortMode: SortMode) {
		this.sortMode = sortMode;
		this.getOrRefreshPosts();
	}

	deleted(id: string) {
		this.postResponses.splice(
			this.postResponses.findIndex(post => post.id == id),
			1
		);
	}

	loadMore() {
		PostRestService.getMany(
			this.userIdFilter,
			this.tagFilter,
			this.sortMode,
			10,
			this.postResponses[this.postResponses.length - 1].id
		).then(postResponses => {
			this.postResponses.push(...postResponses);
		});
	}

	isScrolledIntoView(element: HTMLElement | null): boolean {
		// Verify the element is not null
		if (!element) return false;

		// Get the dom rect
		let domRect = element.getBoundingClientRect();

		// Get the viewport height
		const viewportHeight = Math.max(
			document.documentElement.clientHeight || 0,
			window.innerHeight || 0
		);

		// Check if the top of the element is in the viewport height
		return domRect.top < viewportHeight;
	}
}
