import PostResponse from "./PostResponse";

/**
 * The structure of an image post response.
 *
 * @export
 * @class ImagePostResponse
 * @extends {PostResponse}
 */
export default class ImagePostResponse extends PostResponse {
	/**
	 * The image URL.
	 *
	 * @type {string}
	 */
	imageUrl: string;

	/**
	 * Creates an instance of ImagePostResponse.
	 *
	 * @param {*} imagePostJson The image post JSON.
	 */
	constructor(imagePostJson: any) {
		super(imagePostJson);
		this.imageUrl = imagePostJson.imageUrl;
	}
}
