/**
 * The structure of a tag response.
 *
 * @export
 * @class TagResponse
 */
export default class TagResponse {
	/**
	 * The ID.
	 *
	 * @type {string}
	 */
	id: string;

	/**
	 * The name.
	 *
	 * @type {string}
	 */
	name: string;

	/**
	 * The number of times this tag has been used.
	 *
	 * @type {number}
	 */
	usageCount: number;

	/**
	 * Creates an instance of TagResponse.
	 * 
	 * @param {*} tagResponseJson The tag response JSON.
	 */
	constructor(tagResponseJson: any) {
		this.id = tagResponseJson.id;
		this.name = tagResponseJson.name;
		this.usageCount = tagResponseJson.usageCount;
	}
}
