import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/Views/Home/Home.vue";
import Post from "@/Views/Post/Post.vue";
import User from "@/Views/User/User.vue";
import CreateOrEditPost from "@/Views/CreateOrEditPost.vue";
import CommentPage from "@/Views/Comment/CommentPage.vue";
import CreateOrEditCommunity from "@/Views/CreateOrEditCommunity.vue";
import CommunityPage from "@/Views/Community/CommunityPage.vue";
import CommunitiesPage from "@/Views/Community/CommunitiesPage.vue";
import TagsPage from "@/Views/Tag/TagsPage.vue";
import TagPage from "@/Views/Tag/TagPage.vue";
import ContactPage from "@/Views/Contact/ContactPage.vue";
import HelpPage from "@/Views/Help/HelpPage.vue";
import AboutPage from "@/Views/About/AboutPage.vue";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		component: Home
	},
	{
		path: "/post/:id",
		component: Post
	},
	{
		path: "/create/post",
		component: CreateOrEditPost
	},
	{
		path: "/create/community",
		component: CreateOrEditCommunity
	},
	{
		path: "/community/:id",
		component: CommunityPage
	},
	{
		path: "/community/",
		component: CommunitiesPage
	},
	{
		path: "/tag/",
		component: TagsPage
	},
	{
		path: "/tag/:id",
		component: TagPage
	},
	{
		path: "/contact",
		component: ContactPage
	},
	{
		path: "/help",
		component: HelpPage
	},
	{
		path: "/about",
		component: AboutPage
	},
	// TODO: Abstract a sub router out for users and combine the bottom two routes. Paths will probably have to be changed
	{
		path: "/user/:id",
		component: User
	},
	{
		path: "/comment/:id",
		component: CommentPage
	}
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes
});

export default router;
