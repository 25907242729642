import axios from "axios";
import { getModule } from "vuex-module-decorators";
import AuthenticationModule from "@/Store/Modules/Authentication";
import ApiConfig from "./ApiConfig";
import CommentResponse from "@/Types/Rest/Responses/CommentResponse";
import { Reaction } from "@/Types/Reaction";
import CreateCommentRequest from "@/Types/Rest/Requests/CreateCommentRequest";

const authenticationStore = getModule(AuthenticationModule);

/**
 * A rest service for interacting with the comment API.
 *
 * @export
 * @class CommentRestService
 */
export default class CommentRestService {
	/**
	 * Get a single comment by it's ID.
	 *
	 * @static
	 * @param {string} id The ID.
	 * @returns {Promise<CommentResponse>} A promise containing the comment response.
	 */
	public static async getOne(id: string): Promise<CommentResponse> {
		return axios
			.get(`${ApiConfig.serverUrl}/comment/${id}`, {
				headers: {
					Authorization: `Bearer ${authenticationStore.senderToken}`
				}
			})
			.then(response => {
				return new CommentResponse(response.data);
			});
	}

	/**
	 * Get the comments with the given IDs.
	 *
	 * @static
	 * @param {Array<string>} ids The IDs of the comments.
	 * @returns {Promise<Array<CommentResponse>>} An array the comment responses.
	 */
	public static async getMany(
		ids: Array<string>
	): Promise<Array<CommentResponse>> {
		return axios
			.post(`${ApiConfig.serverUrl}/comment`, ids, {
				headers: {
					Authorization: `Bearer ${authenticationStore.senderToken}`
				}
			})
			.then(response => {
				return (response.data as []).map(json => {
					return new CommentResponse(json);
				});
			});
	}

	/**
	 * Update a comment.
	 *
	 * @static
	 * @param {string} id The ID.
	 * @param {CreateCommentRequest} updateCommentRequest The update comment request.
	 * @returns {Promise<CommentResponse>} A promise containing the updated comment.
	 */
	public static async update(
		id: string,
		updateCommentRequest: CreateCommentRequest
	): Promise<CommentResponse> {
		return axios
			.put(
				`${ApiConfig.serverUrl}/comment/${id}`,
				JSON.stringify(updateCommentRequest),
				{
					headers: {
						Authorization: `Bearer ${authenticationStore.senderToken}`,
						"Content-Type": "application/json"
					}
				}
			)
			.then(response => new CommentResponse(response.data));
	}

	/**
	 * Delete a comment
	 *
	 * @static
	 * @param {string} id The ID.
	 * @returns {Promise<void>} An empty promise.
	 */
	public static async delete(id: string): Promise<void> {
		return axios.delete(`${ApiConfig.serverUrl}/comment/${id}`, {
			headers: {
				Authorization: `Bearer ${authenticationStore.senderToken}`
			}
		});
	}

	/**
	 * Comment on a comment with a given ID.
	 *
	 * @static
	 * @param {string} id The ID.
	 * @param {CreateCommentRequest} createCommentRequest The create comment request.
	 * @returns {Promise<CommentResponse>} A promise containing the created comment response.
	 */
	public static async comment(
		id: string,
		createCommentRequest: CreateCommentRequest
	): Promise<CommentResponse> {
		return axios
			.post(
				`${ApiConfig.serverUrl}/comment/${id}/comment`,
				JSON.stringify(createCommentRequest),
				{
					headers: {
						Authorization: `Bearer ${authenticationStore.senderToken}`,
						"Content-Type": "application/json"
					}
				}
			)
			.then(response => {
				return new CommentResponse(response.data);
			});
	}

	/**
	 * React to a comment with a given ID.
	 *
	 * @static
	 * @param {string} id The ID.
	 * @param {Reaction} reaction The reaction.
	 * @returns {Promise} An empty promise.
	 */
	public static async react(id: string, reaction: Reaction): Promise<void> {
		return axios
			.post(
				`${ApiConfig.serverUrl}/comment/${id}/react`,
				JSON.stringify(reaction),
				{
					headers: {
						Authorization: `Bearer ${authenticationStore.senderToken}`,
						"Content-Type": "application/json"
					}
				}
			)
			.then();
	}

	/**
	 * Remove a reaction from a comment with a given ID.
	 *
	 * @static
	 * @param {string} id The ID.
	 * @returns {Promise} An empty promise.
	 */
	public static async removeReaction(id: string): Promise<void> {
		return axios
			.delete(`${ApiConfig.serverUrl}/comment/${id}/react`, {
				headers: {
					Authorization: `Bearer ${authenticationStore.senderToken}`
				}
			})
			.then();
	}
}
