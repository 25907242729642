import axios, {  } from "axios";
import { getModule } from "vuex-module-decorators";
import AuthenticationModule from "@/Store/Modules/Authentication";
import ApiConfig from "./ApiConfig";
import TagResponse from '@/Types/Rest/Responses/TagResponse';

const authenticationStore = getModule(AuthenticationModule);

/**
 * A rest service for interacting with the tag API.
 *
 * @export
 * @class TagRestService
 */
export default class TagRestService {
	/**
	 * Get many tags.
	 *
	 * @static
	 * @param {number} [count] The maximum tags to retrieve.
	 * @param {string} [afterId] The ID of the tag to start the query from exclusive.
	 * @param {boolean} [reverse=false] Whether or not to search for tags in reverse order.
	 * @returns {Promise<Array<TagResponse>>} A promise containing the tag responses.
	 */
	public static async getMany(
		count?: number,
		afterId?: string,
		reverse: boolean = false
	): Promise<Array<TagResponse>> {
		const queryParams: any = {};
		if (count) queryParams.count = count;
		if (afterId) queryParams.afterId = afterId;
		if (reverse) queryParams.reverse = reverse;

		return axios
			.get(`${ApiConfig.serverUrl}/tag`, {
				headers: {
					Authorization: authenticationStore.isAuthenticated ? `Bearer ${authenticationStore.senderToken}` : ''
				},
				params: queryParams
			})
			.then(response => {
				return (response.data as []).map(tagJson => {
					return new TagResponse(tagJson);
				});
			});
	}
}