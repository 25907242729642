











import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { LoadState } from "@/Types/LoadState";
import CommunityResponse from "../../Types/Rest/Responses/CommunityResponse";
import CommunityRestService from "../../Api/CommunityRestService";

@Component
export default class CommunityPage extends Vue {
	communityLoadState = LoadState.Loading;

	internalCommunityResponse!: CommunityResponse;

	get communityId(): string {
		return this.$route.params.id;
	}

	mounted() {
		CommunityRestService.getOne(this.communityId).then(community => {
			this.internalCommunityResponse = community;
			this.communityLoadState = LoadState.Loaded;
		});
	}
}
