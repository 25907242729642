













import { Component, Vue } from "vue-property-decorator";
import ClickAwayHandler from "@/Components/ClickAwayHandler.vue"

@Component({
	components: {
		ClickAwayHandler
	}
})
export default class PopupContainer extends Vue {
	mounted() {
		document.body.style.overflow = "hidden"
	}

	destroyed() {
		document.body.style.overflow = "auto"
	}
}
