














import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { LoadState } from "@/Types/LoadState";
import CommunityResponse from "../../Types/Rest/Responses/CommunityResponse";
import CommunityRestService from "../../Api/CommunityRestService";
import CompactCommunity from "./Components/CompactCommunity.vue";

@Component({
	components: {
		CompactCommunity
	}
})
export default class CommunitiesPage extends Vue {
	communitiesLoadState = LoadState.Loading;

	internalCommunitiesResponse!: Array<CommunityResponse>;

	mounted() {
		CommunityRestService.getMany().then(communitiesResponse => {
			this.internalCommunitiesResponse = communitiesResponse;
			this.communitiesLoadState = LoadState.Loaded;
		});
	}
}
