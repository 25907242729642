




















import { Component, Prop, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import AuthenticationModule from "@/Store/Modules/Authentication";
import Login from "@/Components/Login.vue";

const authenticationStore = getModule(AuthenticationModule);

@Component({
	components: {
		Login
	}
})
export default class CreatePost extends Vue {
	showLogin = false;

	get authenticated() {
		return authenticationStore.isAuthenticated;
	}

	signInEvent(successful: boolean) {
		if (successful) {
			this.$router.push("/create/post");
		}
	}

	createPost() {
		if (this.authenticated) {
			this.$router.push("/create/post");
		} else {
			this.showLogin = true;
		}
	}
}
