/**
 * The structure of a user sliver.
 *
 * @export
 * @class UserSliver
 */
export default class UserSliver {
	/**
	 * The ID.
	 *
	 * @type {string}
	 */
	id: string;

	/**
	 * The display name.
	 *
	 * @type {string}
	 */
	displayName: string;

	/**
	 * The image URL.
	 *
	 * @type {string}
	 */
	imageUrl: string;

	/**
	 * Creates an instance of UserSliver.
	 *
	 * @param {*} userSliverJson The user sliver JSON.
	 */
	constructor(userSliverJson: any) {
		// TODO: This should be lowercase on the backend
		this.id = userSliverJson.id ?? userSliverJson.Id;
		this.displayName = userSliverJson.displayName ?? userSliverJson.DisplayName;
		this.imageUrl = userSliverJson.imageUrl ?? userSliverJson.ImageUrl;
	}
}
