import PostResponse from "./PostResponse";
import TextPostResponse from "./TextPostResponse";
import LinkPostResponse from "./LinkPostResponse";
import ImagePostResponse from "./ImagePostResponse";

/**
 * A factory for creating post responses. TODO: Is this really a factory though? More of a util at the moment.
 *
 * @export
 * @class PostResponseFactory
 */
export default class PostResponseFactory {
	/**
	 * Create a post response from JSON.
	 *
	 * @static
	 * @param {*} postJson The post JSON.
	 * @returns {PostResponse}
	 */
	static fromJson(postJson: any): PostResponse {
		if ("text" in postJson) {
			return new TextPostResponse(postJson);
		} else if ("linkUrl" in postJson) {
			return new LinkPostResponse(postJson);
		} else if ("imageUrl" in postJson) {
			return new ImagePostResponse(postJson);
		} else {
			throw new TypeError(
				`JSON response '${postJson}' is not a valid post type.`
			);
		}
	}
}
