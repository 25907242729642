














import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import PostRestService from "../../../Api/PostRestService";
import { getModule } from "vuex-module-decorators";
import AuthenticationModule from "@/Store/Modules/Authentication";
import Login from "@/Components/Login.vue";
import CreateCommentRequest from "@/Types/Rest/Requests/CreateCommentRequest";

const authenticationStore = getModule(AuthenticationModule);

@Component({
	components: {
		Login
	}
})
export default class CreateComment extends Vue {
	@Prop({ required: true })
	parentId!: string;

	@Prop({ default: false })
	active!: boolean;

	get isAuthenticated(): boolean {
		return authenticationStore.isAuthenticated;
	}

	get canSubmit(): boolean {
		return this.isAuthenticated && !this.isEmptyOrWhiteSpace(this.comment);
	}

	get userImage(): string | null {
		return authenticationStore.userClaims?.userSliver.imageUrl ?? null;
	}

	get username(): string | null {
		return authenticationStore.userClaims?.userSliver.displayName ?? null;
	}

	comment = "";

	mounted() {
		if (this.active) {
			document.getElementById("commentInput")!.focus();
		}
	}

	submitComment() {
		if (this.isEmptyOrWhiteSpace(this.comment) || !this.isAuthenticated) {
			return;
		}

		PostRestService.comment(
			this.parentId,
			new CreateCommentRequest(this.comment)
		).then(commentResponse => {
			this.comment = '';
			this.$emit("created", commentResponse);
		});
	}

	isEmptyOrWhiteSpace(value: string | null | undefined): boolean {
		return !value || /^\s*$/.test(value);
	}
}
