import UserSliver from "./Rest/Responses/UserSliver";

/**
 * Describes the structure of user claims.
 *
 * @export
 * @class UserClaims
 */
export default class UserClaims {
	/**
	 * The ID.
	 *
	 * @type {UserSliver}
	 */
	userSliver: UserSliver;

	/**
	 * Creates an instance of a user claims.
	 *
	 * @param {any} userClaimsJson The user claims JSON.
	 */
	constructor(userClaimsJson: any) {
		this.userSliver = new UserSliver(userClaimsJson.UserSliver); // TODO: This should be lowercase on the backend
	}

	/**
	 * Create a user claims from a JSON web token.
	 *
	 * @static
	 * @param {(string | null | undefined)} token The token.
	 * @returns {(UserClaims | null)} The created user claims or null.
	 */
	static fromJwt(token: string | null | undefined): UserClaims | null {
		if (!token) {
			return null;
		}

		var base64Url = token.split(".")[1];
		var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
		var jsonPayload = decodeURIComponent(
			atob(base64)
				.split("")
				.map(function(c) {
					return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
				})
				.join("")
		);

		return new UserClaims(JSON.parse(jsonPayload));
	}
}
