import UserCreatableResponse from "./UserCreatableResponse";

/**
 * The structure of a post response.
 *
 * @export
 * @abstract
 * @class PostResponse
 */
export default abstract class PostResponse extends UserCreatableResponse {
	/**
	 * The title.
	 *
	 * @type {string}
	 */
	title: string;

	/**
	 * A list of tags.
	 *
	 * @type {Array<string>}
	 */
	tags: Array<string>;

	/**
	 * Creates an instance of PostResponse.
	 *
	 * @param {*} postResponseJson The post response JSON.
	 */
	constructor(postResponseJson: any) {
		super(postResponseJson);
		this.title = postResponseJson.title;
		this.tags = postResponseJson.tags;
	}
}
