import UserSliver from "./UserSliver";

/**
 * The structure of a community response.
 *
 * @export
 * @class CommunityResponse
 */
export default class CommunityResponse {
	/**
	 * The ID.
	 *
	 * @type {string}
	 */
	id: string;

	/**
	 * The name.
	 *
	 * @type {string}
	 */
	name: string;

	/**
	 * The description.
	 *
	 * @type {string}
	 */
	description: string;

	/**
	 * The creation date measured in ticks since the epoch.
	 *
	 * @type {number}
	 */
	creationDate: number;

	/**
	 * The creator.
	 *
	 * @type {UserSliver}
	 */
	creator: UserSliver;

	/**
	 * Creates an instance of communityResponse.
	 *
	 * @param {*} communityResponseJson The community response JSON.
	 */
	constructor(communityResponseJson: any) {
		this.id = communityResponseJson.id;
		this.name = communityResponseJson.name;
		this.description = communityResponseJson.description;
		this.creationDate = communityResponseJson.creationDate;
		this.creator = communityResponseJson.creator;
	}
}
