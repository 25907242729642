







































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { LoadState } from "@/Types/LoadState";
import PostResponse from "@/Types/Rest/Responses/PostResponse";
import PostRestService from "@/Api/PostRestService";
import CommentRestService from "@/Api/CommentRestService";
import CreateComment from "./Components/CreateComment.vue";
import TextPostResponse from "@/Types/Rest/Responses/TextPostResponse";
import Comment from "./Components/Comment.vue";
import CommentResponse from "../../Types/Rest/Responses/CommentResponse";
import { getModule } from "vuex-module-decorators";
import AuthenticationModule from "@/Store/Modules/Authentication";
import marked from 'marked';
import UserSliver from '@/Types/Rest/Responses/UserSliver';
import ClickAwayHandler from "@/Components/ClickAwayHandler.vue";
import PageContainer from "@/Components/PageContainer.vue";
import UserRestService from '@/Api/UserRestService';
import UserResponse from '@/Types/Rest/Responses/UserResponse';

const authenticationStore = getModule(AuthenticationModule);

@Component({
	components: {
		CreateComment,
		Comment,
		ClickAwayHandler,
		PageContainer
	}
})
export default class Post extends Vue {
	postLoadState = LoadState.Loading;

	postResponse!: PostResponse;

	userLoadState = LoadState.Loading;

	userResponse!: UserResponse;

	commentsLoadState = LoadState.Loading;

	commentResponses = new Array<CommentResponse>();

	displayDropdown = false;

	get postId(): string {
		return this.$route.params.id;
	}

	get userSliver(): UserSliver | null {
		return authenticationStore.userClaims?.userSliver || null;
	}

	get isAuthenticated(): boolean {
		return authenticationStore.isAuthenticated;
	}

	get formattedText(): string {
		const render = new marked.Renderer;

		render.image = function(href: string | null, title: string | null, text: string) {
			return `<img class="marked-image" src="${href}" alt="${text}">`;
		};

		render.paragraph = function(text: string) {
			return `<p class="marked-paragraph">${text}</p>`
		}

		render.code = function(code: string, language: string | undefined, isEscaped: boolean) {
			code = code
				.replace(/&/g, "&amp;")
				.replace(/</g, "&lt;")
				.replace(/>/g, "&gt;")
				.replace(/"/g, "&quot;")
				.replace(/'/g, "&#039;");
			return `<pre class="marked-code-pre"><code class="marked-code">${code}</code></pre>`;
		}

		return marked((this.postResponse as TextPostResponse).text, {
			renderer: render
		});
	}

	@Watch("isAuthenticated")
	authenticationChanged() {
		this.$router.go(0);
	}

	tagBackgroundColor(tagName: string): number {
		const seed = Array.from(tagName)
			.map(character => character.charCodeAt(0))
			.reduce((accumulator, currentValue) => accumulator + currentValue);
		return (seed * 7567) % 360;
	}

	mounted() {
		PostRestService.getOne(this.postId)
			.then(post => {
				this.postResponse = post;
				this.postLoadState = LoadState.Loaded;
			})
			.then(() => {
				return UserRestService.getOne(this.postResponse.creator!.id)
			})
			.then(userResponse => {
				this.userResponse = userResponse;
				this.userLoadState = LoadState.Loaded;
			})
			.then(() => {
				return CommentRestService.getMany(this.postResponse.comments);
			})
			.then(commentResponses => {
				this.commentResponses = commentResponses;
				this.commentsLoadState = LoadState.Loaded;
			})
			.catch(reason => {
				// TODO: handle error
			});
	}

	prettyTime(ticksTime: bigint): string {
		const ticksSinceEpoch = ticksTime - 621355968000000000n;
		const postAgeInMsSinceEpoch = Number(ticksSinceEpoch / 10000n);
		const relativePostAgeInSeconds = (Date.now() - postAgeInMsSinceEpoch);

		// In milliseconds
		const units: any = {
			"year"  : 24 * 60 * 60 * 1000 * 365,
			"month" : 24 * 60 * 60 * 1000 * 365/12,
			"day"   : 24 * 60 * 60 * 1000,
			"hour"  : 60 * 60 * 1000,
			"minute": 60 * 1000,
			"second": 1000
		}

		const relativeTimeFormat = new Intl.RelativeTimeFormat('en', { style: 'long', numeric: "auto" });

		for (var unit in units) {
			if (Math.abs(relativePostAgeInSeconds) > units[unit] || unit == 'second')
				return relativeTimeFormat.format(-Math.round(relativePostAgeInSeconds/units[unit]), <Intl.RelativeTimeFormatUnit> unit);
		}

		return "A while ago";
	}

	confirmDelete() {
		// TODO: Confirm the delete action
		PostRestService.delete(this.postResponse.id)
			.then(() => {
				this.displayDropdown = false;
				this.$emit("deleted");
				const homePath = "/";
				if (this.$router.currentRoute.fullPath == homePath) return;
				this.$router.push("/");
			})
			.catch(reason => {
				// TODO: Handle error
				console.log(reason);
			});
	}

	commentCreated(commentResponse: CommentResponse) {
		this.commentResponses.unshift(commentResponse);
		// Wait for the new comment to be rendered before searching the dom for it
		// Vue.nextTick(() => {
		// 	document.getElementById(commentResponse.id)!.scrollIntoView();
		// });
	}
}
