import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { getModule } from "vuex-module-decorators";
import AuthenticationModule from "@/Store/Modules/Authentication";
import ApiConfig from "./ApiConfig";
import { Reaction } from "@/Types/Reaction";
import PostResponseFactory from "@/Types/Rest/Responses/PostResponseFactory";
import PostResponse from "@/Types/Rest/Responses/PostResponse";
import CreatePostRequest from "@/Types/Rest/Requests/CreatePostRequest";
import CreateTextPostRequest from "@/Types/Rest/Requests/CreateTextPostRequest";
import CreateLinkPostRequest from "@/Types/Rest/Requests/CreateLinkPostRequest";
import CreateImagePostRequest from "@/Types/Rest/Requests/CreateImagePostRequest";
import CommentResponse from "@/Types/Rest/Responses/CommentResponse";
import CreateCommentRequest from "@/Types/Rest/Requests/CreateCommentRequest";
import { SortMode } from "@/Types/SortMode";

const authenticationStore = getModule(AuthenticationModule);

/**
 * A rest service for interacting with the post API.
 *
 * @export
 * @class PostRestService
 */
export default class PostRestService {
	/**
	 * Create a post.
	 *
	 * @static
	 * @param {CreatePostRequest} createPostRequest The create post request.
	 * @returns {Promise<PostResponse>} A promise containing the created post response.
	 */
	public static async create(
		createPostRequest: CreatePostRequest
	): Promise<PostResponse> {
		return axios
			.post(
				`${ApiConfig.serverUrl}/post/${this.getApiPathFromCreateRequest(
					createPostRequest
				)}`,
				JSON.stringify(createPostRequest),
				{
					headers: {
						Authorization: `Bearer ${authenticationStore.senderToken}`,
						"Content-Type": "application/json"
					}
				}
			)
			.then(response => {
				return PostResponseFactory.fromJson(response.data);
			});
	}

	/**
	 * Get a single post by it's ID.
	 *
	 * @static
	 * @param {string} id The ID.
	 * @returns {Promise<PostResponse>} A promise containing the post response.
	 */
	public static async getOne(id: string): Promise<PostResponse> {
		return axios
			.get(`${ApiConfig.serverUrl}/post/${id}`, {
				headers: {
					Authorization: `Bearer ${authenticationStore.senderToken}`
				}
			})
			.then(response => {
				return PostResponseFactory.fromJson(response.data);
			});
	}

	/**
	 * Get many posts.
	 *
	 * @static
	 * @param {string} [creator] Get posts created by a particular user.
	 * @param {string} [tag] Get posts containing a particular tag.
	 * @param {SortMode} [sort] Get the posts in the order of a sort mode.
	 * @param {number} [count] The maximum posts to retrieve.
	 * @param {string} [startId] The ID of the post to start the query from.
	 * @param {boolean} [reverse=false] Whether or not to search for post in reverse order.
	 * @returns {Promise<Array<PostResponse>>} A promise containing the post responses.
	 */
	public static async getMany(
		creator?: string,
		tag?: string,
		sort?: SortMode,
		count?: number,
		startId?: string,
		reverse: boolean = false
	): Promise<Array<PostResponse>> {
		const queryParams: any = {};
		if (creator) queryParams.creator = creator;
		if (tag) queryParams.tag = tag;
		if (sort) queryParams.sort = sort;
		if (count) queryParams.count = count;
		if (startId) queryParams.startId = startId;
		if (reverse) queryParams.reverse = reverse;

		return axios
			.get(`${ApiConfig.serverUrl}/post`, {
				headers: {
					Authorization: authenticationStore.isAuthenticated ? `Bearer ${authenticationStore.senderToken}` : ''
				},
				params: queryParams
			})
			.then(response => {
				return (response.data as []).map(postJson => {
					return PostResponseFactory.fromJson(postJson);
				});
			});
	}

	/**
	 * Update a post.
	 *
	 * @static
	 * @param {string} id The ID.
	 * @param {CreatePostRequest} updateCommentRequest The update post request.
	 * @returns {Promise<PostResponse>} A promise containing the updated post.
	 */
	public static async update(
		id: string,
		updatePostRequest: CreatePostRequest
	): Promise<PostResponse> {
		return axios
			.put(
				`${ApiConfig.serverUrl}/post/${this.getApiPathFromCreateRequest(
					updatePostRequest
				)}/${id}`,
				JSON.stringify(updatePostRequest),
				{
					headers: {
						Authorization: `Bearer ${authenticationStore.senderToken}`,
						"Content-Type": "application/json"
					}
				}
			)
			.then(response => PostResponseFactory.fromJson(response.data));
	}

	/**
	 * Delete a post
	 *
	 * @static
	 * @param {string} id The ID.
	 * @returns {Promise<void>} An empty promise.
	 */
	public static async delete(id: string): Promise<void> {
		return axios.delete(`${ApiConfig.serverUrl}/post/${id}`, {
			headers: {
				Authorization: `Bearer ${authenticationStore.senderToken}`
			}
		});
	}

	/**
	 * Comment on a post with a given ID. TODO: Refactor this for responses.
	 *
	 * @static
	 * @param {string} id The ID.
	 * @param {CreateCommentRequest} createCommentRequest The create comment request.
	 * @returns {Promise<CommentResponse>} The created comment.
	 */
	public static async comment(
		id: string,
		createCommentRequest: CreateCommentRequest
	): Promise<CommentResponse> {
		return axios
			.post(
				`${ApiConfig.serverUrl}/post/${id}/comment`,
				JSON.stringify(createCommentRequest),
				{
					headers: {
						Authorization: `Bearer ${authenticationStore.senderToken}`,
						"Content-Type": "application/json"
					}
				}
			)
			.then(response => {
				return new CommentResponse(response.data);
			});
	}

	/**
	 * React to a post with a given ID.
	 *
	 * @static
	 * @param {string} id The ID.
	 * @param {Reaction} reaction The reaction.
	 * @returns {Promise} The response.
	 */
	public static async react(id: string, reaction: Reaction): Promise<void> {
		return axios
			.post(
				`${ApiConfig.serverUrl}/post/${id}/react`,
				JSON.stringify(reaction),
				{
					headers: {
						Authorization: `Bearer ${authenticationStore.senderToken}`,
						"Content-Type": "application/json"
					}
				}
			)
			.then();
	}

	/**
	 * Remove a reaction from a post with a given ID.
	 *
	 * @static
	 * @param {string} id The ID.
	 * @returns {Promise} The response.
	 */
	public static async removeReaction(id: string): Promise<void> {
		return axios
			.delete(`${ApiConfig.serverUrl}/post/${id}/react`, {
				headers: {
					Authorization: `Bearer ${authenticationStore.senderToken}`
				}
			})
			.then();
	}

	/**
	 * Get the API path for the specified create post request.
	 *
	 * @private
	 * @param {CreatePostRequest} createPostRequest The create post request.
	 * @returns {string}
	 */
	private static getApiPathFromCreateRequest(
		createPostRequest: CreatePostRequest
	): string {
		const postType = createPostRequest.constructor;
		switch (postType) {
			case CreateTextPostRequest:
				return "text";
			case CreateLinkPostRequest:
				return "link";
			case CreateImagePostRequest:
				return "image";
			default:
				throw new TypeError(`Post type '${postType}' is not implemented.`);
		}
	}
}
