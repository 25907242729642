















import DesktopHeader from "@/Components/DesktopHeader.vue";
import MobileHeader from "@/Components/MobileHeader.vue";
import Footer from "@/Components/Footer.vue";
import GoogleUtil from "@/Util/GoogleUtil";

GoogleUtil.init();

export default {
	components: { DesktopHeader, MobileHeader, Footer }
};
