import PostResponse from "./PostResponse";

/**
 * The structure of an text post response.
 *
 * @export
 * @class TextPostResponse
 * @extends {PostResponse}
 */
export default class TextPostResponse extends PostResponse {
	/**
	 * The text.
	 *
	 * @type {string}
	 */
	text: string;

	/**
	 * Creates an instance of TextPostResponse.
	 *
	 * @param {*} textPostJson The text post JSON.
	 */
	constructor(textPostJson: any) {
		super(textPostJson);
		this.text = textPostJson.text;
	}
}
