import axios from "axios";
import { getModule } from "vuex-module-decorators";
import AuthenticationModule from "@/Store/Modules/Authentication";
import CreateCommunityRequest from "@/Types/Rest/Requests/CreateCommunityRequest";
import CommunityResponse from "@/Types/Rest/Responses/CommunityResponse";
import ApiConfig from "./ApiConfig";

const authenticationStore = getModule(AuthenticationModule);

/**
 * A rest service for interacting with the user API.
 *
 * @export
 * @class UserRestService
 */
export default class CommunityRestService {
	/**
	 * Create a community.
	 *
	 * @static
	 * @param {CreateCommunityRequest} createCommunityRequest The create community request.
	 * @returns {Promise<CommunityResponse>}
	 */
	public static async create(
		createCommunityRequest: CreateCommunityRequest
	): Promise<CommunityResponse> {
		return axios
			.post(
				`${ApiConfig.serverUrl}/community`,
				JSON.stringify(createCommunityRequest),
				{
					headers: {
						Authorization: `Bearer ${authenticationStore.senderToken}`,
						"Content-Type": "application/json"
					}
				}
			)
			.then(response => new CommunityResponse(response.data));
	}

	/**
	 * Get a single community by it's ID.
	 *
	 * @static
	 * @param {string} id The ID.
	 * @returns {Promise<CommunityResponse>} A promise containing the community.
	 */
	public static async getOne(id: string): Promise<CommunityResponse> {
		return axios
			.get(`${ApiConfig.serverUrl}/community/${id}`, {
				headers: {
					Authorization: `Bearer ${authenticationStore.senderToken}`
				}
			})
			.then(response => new CommunityResponse(response.data));
	}

	/**
	 * Get many communities.
	 *
	 * @static
	 * @returns {Promise<Array<CommunityResponse>>} A promise containing the communities.
	 */
	public static async getMany(): Promise<Array<CommunityResponse>> {
		return axios
			.get(`${ApiConfig.serverUrl}/community`, {
				headers: {
					Authorization: `Bearer ${authenticationStore.senderToken}`
				}
			})
			.then(response => {
				return (response.data as []).map(communityJson => {
					return new CommunityResponse(communityJson);
				});
			});
	}

	/**
	 * Update a community.
	 *
	 * @static
	 * @param {string} id The ID.
	 * @param {CreateCommunityRequest} updateCommunityRequest The update community request.
	 * @returns {Promise<CommunityResponse>} A promise containing the updated community.
	 */
	public static async update(
		id: string,
		updateCommunityRequest: CreateCommunityRequest
	): Promise<CommunityResponse> {
		return axios
			.put(
				`${ApiConfig.serverUrl}/community/${id}`,
				JSON.stringify(updateCommunityRequest),
				{
					headers: {
						Authorization: `Bearer ${authenticationStore.senderToken}`,
						"Content-Type": "application/json"
					}
				}
			)
			.then(response => new CommunityResponse(response.data));
	}
}
