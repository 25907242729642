




























import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { LoadState } from "@/Types/LoadState";
import CommunityRestService from "@/Api/CommunityRestService";
import CommunityResponse from "../Types/Rest/Responses/CommunityResponse";
import CreateCommunityRequest from "../Types/Rest/Requests/CreateCommunityRequest";

@Component
export default class CreateOrEditCommunity extends Vue {
	communityLoadState = LoadState.Loading;

	internalCommunityResponse: CommunityResponse = new CommunityResponse({
		name: "",
		description: ""
	});

	get communityId(): string | null {
		return this.$route.query.communityId as string | null;
	}

	mounted() {
		if (this.communityId) {
			CommunityRestService.getOne(this.communityId).then(community => {
				this.internalCommunityResponse = community;
				this.communityLoadState = LoadState.Loaded;
			});
		} else {
			this.communityLoadState = LoadState.Loaded;
		}
	}

	createOrUpdate() {
		// console.log(this.internalCommunityResponse);

		// return;

		let communityPromise: Promise<CommunityResponse>;
		if (this.communityId) {
			communityPromise = CommunityRestService.update(
				this.communityId,
				new CreateCommunityRequest(
					// The name can't actually be updated
					this.internalCommunityResponse.name,
					this.internalCommunityResponse.description
				)
			);
		} else {
			communityPromise = CommunityRestService.create(
				new CreateCommunityRequest(
					this.internalCommunityResponse.name,
					this.internalCommunityResponse.description
				)
			);
		}
		communityPromise.then(community => {
			this.$router.push(`/community/${community.id}`);
		});
	}
}
