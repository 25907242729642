




































import TextPostResponse from "@/Types/Rest/Responses/TextPostResponse";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Reaction } from "@/Types/Reaction";
import marked from "marked";
import AuthenticationModule from "@/Store/Modules/Authentication";
import PostRestService from "@/Api/PostRestService";
import { getModule } from "vuex-module-decorators";

const authenticationStore = getModule(AuthenticationModule);

const reactionsToReputes = new Map<Reaction, number>();
reactionsToReputes.set(Reaction.Like, 1);
reactionsToReputes.set(Reaction.Dislike, -1);

@Component({
	components: {}
})
export default class AltCompactPost extends Vue {
	@Prop({required: true})
	post!: TextPostResponse

	get postImage(): string | null {
		const regexMatch = this.post.text.match(/!\[(.*?)\]\((.*?)\)/);
		return regexMatch?.[2] ?? null;
	}

	get userReaction(): Reaction | null {
		return this.post.userReaction;
	}

	get formattedText(): string {
		const render = new marked.Renderer;

		render.image = function(href: string | null, title: string | null, text: string) {
			return `<img class="marked-image" src="${href}" alt="${text}">`;
		};

		render.paragraph = function(text: string) {
			return `<p class="marked-text marked-paragraph">${text}</p>`
		}

		render.heading = function(text: string, level: 1 | 2 | 3 | 4 | 5 | 6, raw: string, slugger: marked.Slugger) {
			return `<h${level} class="marked-text marked-heading marked-h${level}">${text}</h${level}>`
		}

		render.code = function(code: string, language: string | undefined, isEscaped: boolean) {
			code = code
				.replace(/&/g, "&amp;")
				.replace(/</g, "&lt;")
				.replace(/>/g, "&gt;")
				.replace(/"/g, "&quot;")
				.replace(/'/g, "&#039;");
			return `<pre class="marked-code-pre"><code class="marked-code">${code}</code></pre>`;
		}

		return marked(this.post.text, {
			renderer: render
		});
	}

	get prettyRepute(): string {
		return (
			this.post.repute +
			(!!this.post.userReaction
				? reactionsToReputes.get(this.post.userReaction)
				: 0)!
		).toString();
	}

	mounted() {
		// Remove the user reaction from the reputation to make downstream calculations easier
		this.post.repute -= (!!this.post
			.userReaction
			? reactionsToReputes.get(this.post.userReaction)
			: 0)!;
	}

	react(reaction: Reaction) {
		if (!authenticationStore.isAuthenticated) {
			// TODO: figure out a way to still perform the reaction
			return;
		}

		const oldReaction = this.post.userReaction;

		if (oldReaction === reaction) {
			this.post.userReaction = null;
			PostRestService.removeReaction(this.post.id).catch(
				reason => {
					// TODO: Handle error
				}
			);
		} else {
			this.post.userReaction = reaction;
			PostRestService.react(this.post.id, reaction).catch(
				reason => {
					// TODO: Handle error
				}
			);
		}
	}
}
