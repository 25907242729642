




import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { LoadState } from "@/Types/LoadState";
import CommunityResponse from "../../../Types/Rest/Responses/CommunityResponse";

@Component
export default class CompactCommunity extends Vue {
	@Prop({ required: true })
	communityResponse!: CommunityResponse;
}
