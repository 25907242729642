

























import PostRestService from "@/Api/PostRestService";
import { LoadState } from "@/Types/LoadState";
import PostResponse from "@/Types/Rest/Responses/PostResponse";
import TextPostResponse from "@/Types/Rest/Responses/TextPostResponse";
import { SortMode } from "@/Types/SortMode";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class TopPosts extends Vue {
	postResponses!: Array<PostResponse>;

	loadState = LoadState.Loading;

	postImage(post: TextPostResponse): string | null {
		const regexMatch = post.text.match(/!\[(.*?)\]\((.*?)\)/);
		return regexMatch?.[2] ?? null;
	}

	mounted() {
		PostRestService.getMany(
			undefined,
			undefined,
			SortMode.Popular,
			3
		).then(postResponses => {
			this.postResponses = postResponses;
			this.loadState = LoadState.Loaded;
		});
	}
}
