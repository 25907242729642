




















import TagRestService from "@/Api/TagRestService";
import TagResponse from "@/Types/Rest/Responses/TagResponse";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ExploreTags extends Vue {
	@Prop({ default: 10 })
	tagsCount!: number;

	tags: Array<TagResponse> = [];

	tagBackgroundColor(tagName: string): number {
		const seed = Array.from(tagName)
			.map(character => character.charCodeAt(0))
			.reduce((accumulator, currentValue) => accumulator + currentValue);
		return (seed * 7567) % 360;
	}

	mounted() {
		TagRestService.getMany(this.tagsCount).then(tags => {
			this.tags = tags;
		});
	}
}
