import UserCreatableResponse from "./UserCreatableResponse";

/**
 * The structure of a comment response.
 *
 * @export
 * @abstract
 * @class CommentResponse
 */
export default class CommentResponse extends UserCreatableResponse {
	/**
	 * The slug.
	 *
	 * @type {string}
	 */
	slug: string;

	/**
	 * The value.
	 *
	 * @type {string}
	 */
	value: string;

	/**
	 * Creates an instance of CommentResponse.
	 *
	 * @param {*} commentResponseJson The comment response JSON.
	 */
	constructor(commentResponseJson: any) {
		super(commentResponseJson);
		this.slug = commentResponseJson.slug;
		this.value = commentResponseJson.value;
	}
}
