















































import { Component, Vue } from "vue-property-decorator";
import Tag from "./Components/Tag.vue";
import MobileTag from "./Components/MobileTag.vue";
import TagRestService from "@/Api/TagRestService";
import TagResponse from "@/Types/Rest/Responses/TagResponse";
import PageContainer from "@/Components/PageContainer.vue";
import { LoadState } from "@/Types/LoadState";
import AdvertisementPromotion from "@/Components/Sidebar/AdvertisementPromotion.vue";
import TopPosts from "@/Components/Sidebar/TopPosts.vue";
import SupportUs from "@/Components/Sidebar/SupportUs.vue";
import SiteMeta from "@/Components/Sidebar/SiteMeta.vue";

@Component({
	components: {
		Tag,
		MobileTag,
		PageContainer,
		AdvertisementPromotion,
		TopPosts,
		SupportUs,
		SiteMeta,
	}
})
export default class TagsPage extends Vue {
	tagLoadState = LoadState.Loading;

	nextPageLoadState = LoadState.Loaded;

	lastPageLoaded = false;

	// This property must be initialized in order for Vue to detect changes to it
	tagResponses: Array<TagResponse> = [];

	afterId: string | undefined;

	get endMessage(): HTMLElement | null {
		return document.getElementById("end-message");
	}

	mounted() {
		TagRestService.getMany(
			20,
			this.afterId
		).then(tagResponses => {
			this.tagResponses = tagResponses;
			this.tagLoadState = LoadState.Loaded;
		});

		window.onscroll = () => {
			// Make sure the first page has loaded
			if (this.tagLoadState != LoadState.Loaded) return;

			// Make sure the next page is not loading
			if (this.nextPageLoadState != LoadState.Loaded) return;

			// Make sure we haven't reached the last page
			if (this.lastPageLoaded) return;

			// Check if end message is scrolled into view
			if (!this.isScrolledIntoView(this.endMessage)) return;

			// If it is scrolled into view load the next page (and set the loading to true)
			this.nextPageLoadState = LoadState.Loading;
			TagRestService.getMany(
				10,
				this.tagResponses[this.tagResponses.length - 1].id
			).then(tagResponses => {
				// Once loaded, see if a full page was returned. If not, end of pages reached, if so set loading to false (so the next page can be loaded)
				if (tagResponses.length < 10) this.lastPageLoaded = true;
				this.tagResponses.push(...tagResponses);
				this.nextPageLoadState = LoadState.Loaded;
			});
		};

	}

	isScrolledIntoView(element: HTMLElement | null): boolean {
		// Verify the element is not null
		if (!element) return false;

		// Get the dom rect
		let domRect = element.getBoundingClientRect();

		// Get the viewport height
		const viewportHeight = Math.max(
			document.documentElement.clientHeight || 0,
			window.innerHeight || 0
		);

		// Check if the top of the element is in the viewport height
		return domRect.top < viewportHeight;
	}
}
