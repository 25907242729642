











import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { LoadState } from "@/Types/LoadState";
import CommentRestService from "@/Api/CommentRestService";
import CommentResponse from "@/Types/Rest/Responses/CommentResponse";
import Comment from "@/Views/Post/Components/Comment.vue";

@Component({
	components: {
		Comment
	}
})
export default class CommentPage extends Vue {
	commentLoadState = LoadState.Loading;

	// The initialization here is to workaround an issue when uninitialized data is not watched by vue
	internalCommentResponse: CommentResponse | null = null;

	get parentLink(): string {
		const slugParts = this.internalCommentResponse!.slug.split(".");
		return slugParts.length == 1
			? `/post/${slugParts[0]}`
			: `/comment/${slugParts[slugParts.length - 1]}`;
	}

	mounted() {
		CommentRestService.getOne(this.$route.params.id).then(commentResponse => {
			this.internalCommentResponse = commentResponse;
			this.commentLoadState = LoadState.Loaded;
		});
	}
}
