






import { Component, Vue } from "vue-property-decorator";

@Component
export default class ClickAwayHandler extends Vue {
	mounted() {
		window.addEventListener("click", this.clickAwayHandler);
	}

	destroyed() {
		window.removeEventListener("click", this.clickAwayHandler);
	}

	clickAwayHandler(event: MouseEvent) {
		// TODO: Make it so the event is only emitted when the mouse event is click down outside of the element
		const contentElement = this.$refs.content as HTMLElement;
		if (!contentElement.contains(event.target as HTMLElement))
			this.$emit("clicked-away");
	}
}
