






















import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import CommentRestService from "../../../Api/CommentRestService";
import CreateCommentRequest from "@/Types/Rest/Requests/CreateCommentRequest";
import ClickAwayHandler from "@/Components/ClickAwayHandler.vue";

/**
 * Header component for mobile devices.
 */
@Component({
	components: {
		ClickAwayHandler
	}
})
export default class CreateOrEditCommentOverlay extends Vue {
	@Prop({ default: null })
	initialComment!: string | null;

	text = this.initialComment ?? "";

	mounted() {
		document.getElementById("text")!.focus();
		// TODO: Temporarily disable scrolling
	}

	submit() {
		this.$emit("submitted", new CreateCommentRequest(this.text));
	}
}
