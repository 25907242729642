/**
 * Describes the structure of a request to create a community.
 *
 * @export
 * @class CreateCommunityRequest
 */
export default class CreateCommunityRequest {
	/**
	 * The name.
	 *
	 * @type {string}
	 */
	name: string;

	/**
	 * The description.
	 *
	 * @type {string}
	 */
	description: string;

	/**
	 * Creates an instance of CreateCommunityRequest.
	 *
	 * @param {string} name The name.
	 * @param {string} description The description.
	 */
	constructor(name: string, description: string) {
		this.name = name;
		this.description = description;
	}
}
