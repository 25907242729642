import CreatePostRequest from "./CreatePostRequest";

/**
 * Describes the structure of a request to create a text post.
 *
 * @export
 * @class CreateTextPostRequest
 * @extends {CreatePostRequest}
 */
export default class CreateTextPostRequest extends CreatePostRequest {
	/**
	 * The text.
	 *
	 * @type {(string | null)}
	 */
	text: string | null;

	/**
	 * Creates an instance of CreateTextPostRequest.
	 *
	 * @param {string} title The title.
	 * @param {Array<string>} tags A list of tags.
	 * @param {(string | null)} text The text.
	 */
	constructor(title: string, tags: Array<string>, text: string | null) {
		super(title, tags);
		this.text = text;
	}
}
