import { Module, VuexModule, Mutation } from "vuex-module-decorators";
import Store from "@/Store";
import { LoadState } from "@/Types/LoadState";
import UserClaims from "@/Types/UserClaims";
import { UserPermission } from "@/Types/UserPermission";

/**
 * The store module for authentication.
 *
 * @export
 * @class AuthenticationModule
 * @extends {VuexModule}
 */
@Module({
	dynamic: true,
	store: Store,
	name: "authentication",
	namespaced: true
})
export default class AuthenticationModule extends VuexModule {
	private _senderToken: string | null = localStorage.getItem("SenderToken");
	private _googleLoadState: LoadState = LoadState.Loading;
	private _userClaims: UserClaims | null = UserClaims.fromJwt(
		this._senderToken
	);

	/**
	 * Set the sender token.
	 *
	 * @param {(string | null)} senderToken The sender token to set.
	 * @returns {void}
	 */
	@Mutation
	public setSenderToken(senderToken: string | null): void {
		this._senderToken = senderToken;
		this._userClaims = UserClaims.fromJwt(senderToken);
		if (this._senderToken == null) {
			localStorage.removeItem("SenderToken");
			return;
		}

		localStorage.setItem("SenderToken", this._senderToken);
	}

	/**
	 * Get the sender token if set or null otherwise.
	 *
	 * @readonly
	 * @type {(string | null)} The token or null.
	 */
	public get senderToken(): string | null {
		return this._senderToken;
	}

	/**
	 * Get the user claims if set or null otherwise.
	 *
	 * @readonly
	 * @type {(UserClaims | null)} The user claims or null.
	 */
	public get userClaims(): UserClaims | null {
		return this._userClaims;
	}

	/**
	 * Get whether or not the user is authenticated.
	 *
	 * @readonly
	 * @type {boolean}
	 */
	public get isAuthenticated(): boolean {
		const padSeconds = 3600;
		const currentSeconds = Math.round(Date.now() / 1000);
		return (this._senderToken ? true : false) && ((currentSeconds + padSeconds) < this.tokenExpiration!);
	}

	/**
	 * Set the load state of the google APIs.
	 *
	 * @param {LoadState} loadState The load state.
	 */
	@Mutation
	public setGoogleLoadState(loadState: LoadState) {
		this._googleLoadState = loadState;
	}

	/**
	 * Get the load state of the google APIs.
	 *
	 * @readonly
	 * @type {LoadState} The load state.
	 */
	public get googleLoadState(): LoadState {
		return this._googleLoadState;
	}

	/**
	 * Get the token expiration time in milliseconds sense the epoch.
	 *
	 * @readonly
	 * @type {(number | null)} The number of milliseconds or null if the token is not set.
	 */
	get tokenExpiration(): number | null {
		if (this._senderToken == null) {
			return null;
		}

		var base64Payload = this._senderToken.split(".")[1];
		var base64 = base64Payload.replace(/-/g, "+").replace(/_/g, "/");
		var jsonPayload = decodeURIComponent(
			atob(base64)
				.split("")
				.map(function(c) {
					return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
				})
				.join("")
		);

		return JSON.parse(jsonPayload).exp
	}
}
