import CreatePostRequest from "./CreatePostRequest";

/**
 * Describes the structure of a request to create an image post.
 *
 * @export
 * @class CreateImagePostRequest
 * @extends {CreatePostRequest}
 */
export default class CreateImagePostRequest extends CreatePostRequest {
	/**
	 * The image URL.
	 *
	 * @type {(string | null)}
	 */
	imageUrl: string | null;

	/**
	 * Creates an instance of CreateImagePostRequest.
	 *
	 * @param {string} title The title.
	 * @param {string} imageUrl The image URL.
	 */
	constructor(title: string, imageUrl: string) {
		super(title, []); // TODO: Tags
		this.imageUrl = imageUrl;
	}
}
