














import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class Footer extends Vue {
}
