import PostResponse from "./PostResponse";

/**
 * The structure of an link post response.
 *
 * @export
 * @class LinkPostResponse
 * @extends {PostResponse}
 */
export default class LinkPostResponse extends PostResponse {
	/**
	 * The link URL.
	 *
	 * @type {string}
	 */
	linkUrl: string;

	/**
	 * Creates an instance of LinkPostResponse.
	 *
	 * @param {*} linkPostJson The link post JSON.
	 */
	constructor(linkPostJson: any) {
		super(linkPostJson);
		this.linkUrl = linkPostJson.linkUrl;
	}
}
