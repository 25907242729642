import CreatePostRequest from "./CreatePostRequest";

/**
 * Describes the structure of a request to create a link post.
 *
 * @export
 * @class CreateLinkPostRequest
 * @extends {CreatePostRequest}
 */
export default class CreateLinkPostRequest extends CreatePostRequest {
	/**
	 * The link URL.
	 *
	 * @type {(string | null)}
	 */
	linkUrl: string | null;

	/**
	 * Creates an instance of CreateLinkPostRequest.
	 *
	 * @param {string} title The title.
	 * @param {string} linkUrl The link URL.
	 */
	constructor(title: string, linkUrl: string) {
		super(title, []); // TODO: Tags
		this.linkUrl = linkUrl;
	}
}
