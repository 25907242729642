















import PostRestService from "@/Api/PostRestService";
import TagResponse from "@/Types/Rest/Responses/TagResponse";
import TextPostResponse from "@/Types/Rest/Responses/TextPostResponse";
import { SortMode } from "@/Types/SortMode";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class MobileTag extends Vue {
	@Prop({required: true})
	tagResponse!: TagResponse

	tagImage: string | null = null;

	mounted() {
		PostRestService.getMany(
			undefined, 
			this.tagResponse.name,
			SortMode.Popular,
			3
		).then(postResponses => {
			for(const postResponse of postResponses as TextPostResponse[]) {
				const regexMatch = postResponse.text.match(/!\[(.*?)\]\((.*?)\)/);
				const imageUrl = regexMatch?.[2];
				if (imageUrl != null) {
					this.tagImage = imageUrl;
					break;
				}
			}
		});
	}

	tagBackgroundColor(tagName: string): number {
		const seed = Array.from(tagName)
			.map(character => character.charCodeAt(0))
			.reduce((accumulator, currentValue) => accumulator + currentValue);
		return (seed * 7567) % 360;
	}
}
