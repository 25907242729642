import axios from "axios";

const imgurClientId = process.env.VUE_APP_IMGUR_CLIENT_ID;

/**
 * A rest service for interacting with the Imgur API.
 *
 * @export
 * @class ImgurRestService
 */
export default class ImgurRestService {
	/**
	 * Post an image.
	 *
	 * @param {File} imageFile The image file to post.
	 * @returns {Promise<any>} A promise containing the response.
	 */
	public static postImage(imageFile: File): Promise<any> {
		// Create the form data
		const formData = new FormData();

		// Add the image to the form data
		formData.append("image", imageFile)

		// Issue the request
		return axios
			.post(`https://api.imgur.com/3/image`, formData, {
				headers: {
					Authorization: `Client-ID ${imgurClientId}`
				}
			})
			.then(response => {
				// TODO: Create object for response
				return response;
			});
	}
}