






































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import PostContainer from "@/Components/PostContainer.vue";
import PageContainer from "@/Components/PageContainer.vue";
import CreatePost from "./Components/CreatePost.vue";
import ExploreTags from "@/Components/Sidebar/ExploreTags.vue";
import AdvertisementPromotion from "@/Components/Sidebar/AdvertisementPromotion.vue";
import TopPosts from "@/Components/Sidebar/TopPosts.vue";
import SupportUs from "@/Components/Sidebar/SupportUs.vue";
import SiteMeta from "@/Components/Sidebar/SiteMeta.vue";
import Login from "@/Components/Login.vue";
import { getModule } from "vuex-module-decorators";
import AuthenticationModule from "@/Store/Modules/Authentication";

const authenticationStore = getModule(AuthenticationModule);

@Component({
	name: "home",
	components: {
		PostContainer,
		CreatePost,
		ExploreTags,
		PageContainer,
		AdvertisementPromotion,
		TopPosts,
		SupportUs,
		SiteMeta,
		Login
	}
})
export default class Home extends Vue {
	showLogin = false;

	createPost() {
		if (authenticationStore.isAuthenticated) {
			this.$router.push("/create/post");
		} else {
			this.showLogin = true;
		}
	}

	signInEvent(successful: boolean) {
		if (successful) {
			this.$router.push("/create/post");
		}
	}
}
